import React from "react"
import {Route, Routes} from "react-router-dom";
import LoginPage from "./LoginPage";
import LoginVerify from "./LoginVerify";

const LoginRouter = () => {

    return(
        <Routes>
            <Route path={"/"} element={<LoginPage/>}/>
            <Route path={"/login"} element={<LoginPage/>}/>
            <Route path={"/verify"} element={<LoginVerify/>}/>
            <Route path="*" element={<LoginPage />} />
        </Routes>
    )
}
export default LoginRouter
