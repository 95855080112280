import React from "react";
import store, {persistor}  from "./store";
import { Provider } from "react-redux";
import Token from "./Token";
import {PersistGate} from "redux-persist/integration/react";

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Token />
            </PersistGate>
        </Provider>
    );
}

export default App;
