import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Loader from "../../../components/My/Loader";
import {IconTabs} from "../../../components/tabs";
import Header from "../../../components/My/Header";
import PaymentGateway from "./PaymentGateway/PaymentGateway";
import Basic from "./Basic/Basic";
import ListOfSubject from "./ListOfSubject/ListOfSubject";
import {apiGetBillingDetail} from "../Actions";
import {SET_FILTER} from "../../../reducers/filters";
import {useDispatch, useSelector} from "react-redux";


const Detail = (props) => {


    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)
    const {billingId} = useParams()
    let dispatch = useDispatch()

    let {billingDetailTabIndex} = useSelector(state => state.filters)

    useEffect(() => {
        get()
    }, [])

    const get = () => {
        setLoading(true)
        apiGetBillingDetail(billingId, (data) => {
            setData(data)
            setLoading(false)
        }, error => {

            setLoading(false)
        })
    }

    const getName = () => {
        if (data) return " (" + data.month + "/" + data.year + ")"
        return ""
    }

    const setCacheIndex = (index) =>{
        dispatch({type: SET_FILTER, page: "billingDetailTabIndex", payload: index})
    }

    const getBody = () => {
        if (loading) return <Loader/>
        if (!data) return null
        if (data) {
            return (
                <IconTabs defaultIndex={billingDetailTabIndex} setCacheIndex={setCacheIndex} tabs={[
                    {
                        index: 0,
                        title: <div>Základní informace</div>,
                        content: <>
                            <Basic id={billingId} data={data} loading={loading}/>
                        </>
                    },
                    {
                        index: 1,
                        title: <div>Seznam subjektů</div>,
                        content: <ListOfSubject {...props}/>
                    },
                    {
                        index: 2,
                        title: <div>Platební brány </div>,
                        content: <PaymentGateway billingId={billingId} {...props}/>
                    }
                ]}/>
            )
        }
    }

    return (
        <Header data={[
            {text: "Vyúčtování", link: "/billing/list"},
            {text: "Detail vyúčtování" + getName(), isMain: true},
        ]}>
            {getBody()}
        </Header>

    )
}

export default Detail
