import React from "react";
import Widget from "../../../components/widget";
import DateTimeField from "../../../FinalForm/DateTimeField";
import {Form} from "react-final-form";
import InputField from "../../../FinalForm/InputField";
import {Button} from "../../../components/My/Button";
import SelectField from "../../../FinalForm/SelectField";


const Filter = ({get, loading}) => {

    return (
        <Widget>
            <Form onSubmit={get} render={({handleSubmit}) => (
                <>
                    <div className={"flex flex-row"}>
                        <div className={"w-1/4"}>
                            <DateTimeField name={"dateFrom"} label={"Datum od"} isMandatory={false} onEnter={handleSubmit}/>
                        </div>
                        <div className={"w-1/4"}>
                            <DateTimeField name={"dateTo"} label={"Datum od"} isMandatory={false} onEnter={handleSubmit}/>
                        </div>
                        <div className={"w-1/4"}>
                            <InputField name={"price"} label={"Cena"} onEnter={handleSubmit} isMandatory={false}/>
                        </div>
                        <div className={"w-1/4"}>
                            <InputField name={"vs"} label={"Variabilní symbol"} onEnter={handleSubmit} isMandatory={false}/>
                        </div>
                        <div className={"w-1/4"}>
                            <InputField name={"enigooOrderId"} label={"ENIGOO ID"} onEnter={handleSubmit} isMandatory={false}/>
                        </div>
                        <div className={"w-1/4"}>
                            <SelectField name={"status"} options={[{label: "Čeká na zaplacení", value: 0}, {label: "Došlo k chybě", value: 1}, {label: "Úspěšně dokončeno", value: 2}]} label={"Stav objednávky"}/>
                        </div>
                    </div>
                    <div className={"w-full text-right"}>
                        <Button text={"Vyhledat"} loading={loading} onClick={handleSubmit}/>
                    </div>
                </>
            )}/>

        </Widget>
    )
}

export default Filter
