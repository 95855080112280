import React, {useEffect, useState} from "react";
import {apiGetStatsDaily} from "../Actions";
import Toast from "cogo-toast";
import Header from "../../../components/My/Header";
import TotalSalesGraph from "./TotalSalesGraph";
import Widget from "../../../components/widget";
import {Filter} from "./Filter";
import {useSelector} from "react-redux";
import Loader from "../../../components/My/Loader";
import Datatable from "../../../components/datatable";
import Currency from "../../../components/My/Currency";
import FileDownload from "js-file-download";



export const Daily = () => {

    let [loading, setLoading] = useState(false)
    let [loadingXls, setLoadingXls] = useState(false)
    let [data, setData] = useState(null)
    let {statsOfSubjectFilter} = useSelector(state => state.filters)

    useEffect(() => {
        getStats()
    }, [])

    const getStats = (filter = statsOfSubjectFilter, typeExport = 'JSON') => {
        setLoading(true)
        filter.typeExport = typeExport
        apiGetStatsDaily(filter, data => {
            if (typeExport === "XLS_LIST") {
                let blob = new Blob([data.data], {type: "application/vnd.ms-excel"});
                FileDownload(blob, data.headers['content-disposition'].replace('attachment; filename=', ''))
                setLoadingXls(false)
                setLoading(false)
            }else {
                setData(data)
                setLoading(false)
            }
        }, error => {
            setLoading(false)
            Toast.warn("Při načítání došlo k chybě.")
        })
    }

    const getTable = () => {
        let columns = [
            {Header: 'Název subjektu', accessor: "subject.name"},
            {Header: 'Obrat', accessor: d => <Currency type={"text"} price={d.total.price} currency={d.subject.currency}/>},
        ];

        return <Datatable data={data['bySubject']} columns={columns}/>
    }


    return (
        <Header data={[{text: "Globalní statistika", isMain: true}]}>
            <Widget>
                <Filter get={getStats} filter={statsOfSubjectFilter} loadingXls={loadingXls}/>
            </Widget>
            <Widget title={"Celkové tržby"}>
                {loading && <Loader/>}
                {data && <TotalSalesGraph data={data} typeOfData={"price"}/>}
            </Widget>
            <Widget title={"Celkové počty kusů"}>
                {loading && <Loader/>}
                {data && <TotalSalesGraph data={data} typeOfData={"count"}/>}
            </Widget>
            <Widget title={"Dle subjektů"}>
                {loading && <Loader/>}
                {data && getTable()}
            </Widget>

        </Header>
    )
}



