import React, {useState} from "react";
import Currency from "../../../../../components/My/Currency";
import Datatable from "../../../../../components/datatable";
import moment from "moment";
import {Button} from "../../../../../components/My/Button";
import Modal from "../../../../../components/My/Modal";
import {Form} from "react-final-form";
import CurrencyField from "../../../../../FinalForm/CurrencyField";
import DateTimeField from "../../../../../FinalForm/DateTimeField";
import InputField from "../../../../../FinalForm/InputField";
import {apiSetAdvanceToSubject} from "../../../Actions";
import Toast from "cogo-toast";

const Advance = (props) => {

    let [modal, setModal] = useState(false)
    let [loading, setLoading] = useState(false)
    let submit = null
    let columns = [
        {Header: 'Datum', accessor: d => moment(d.date).format("llll")},
        {
            Header: 'Záloha',
            accessor: d => (<Currency type={"text"} currency={props.subject.currency} price={d.amount}/>)
        },
        {Header: 'Poznámka', accessor: "note"}
    ]

    const save = (values) => {
        setLoading(true)
        apiSetAdvanceToSubject(props.billingId, props.subjectId, values, (data)=>{
            setLoading(false);
            Toast.success("Dokončeno.")
            props.get()
        }, error =>{
            Toast.warn("Došlo k chybě.")
            setLoading(false)
        })
    }

    const getForm = () => {
      return(
          <Form onSubmit={save} render={({handleSubmit})=>{
              submit = handleSubmit
             return(
                 <div className={"flex flex-col"}>
                     <div className={"w-full"}>
                         <CurrencyField name={"amount"} label={"Částka zálohy"} minValue={1} isMandatory={true}/>
                     </div>
                     <div className={"w-full"}>
                         <DateTimeField name={"date"} label={"Datum vytvoření"} isMandatory={true}/>
                     </div>
                     <div className={"w-full"}>
                         <InputField label={"Interní poznámka - nepropisuje se"} name={"note"} isMandatory={false}/>
                     </div>
                 </div>
             )
          }}/>
      )
    }

    return <>
        <div className={"flex flex-row"}>
            <span className="ml-auto"></span>
            <Button text={"Vytvořit zálohu"} loading={loading} onClick={()=>setModal(true)}/>
        </div>
        <Datatable data={props.advance} columns={columns}/>

        {modal &&
        <Modal body={getForm()} title={"Vytvořit zálohu"} visible={modal} close={() => setModal(false)}
               footer={
                   <>
                       <Button outline={true} text={"Zavřít"} onClick={() => setModal(false)}/>
                       <Button text={"Potvrdit"} onClick={() => submit()} loading={loading}/>
                   </>
               }
        />}
    </>
}
export default Advance
