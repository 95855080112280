import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import Datatable from "../../../../components/datatable";
import {apiGetExportBilling, apiGetSubjectBillingList} from "../../Actions";
import {Button} from "../../../../components/My/Button";
import {Filter} from "./Filter";
import Loader from "../../../../components/My/Loader";
import Toast from "cogo-toast";
import {useDispatch, useSelector} from "react-redux";
import Dropdown from "../../../../components/dropdowns/dropdown-1";
import {FaFileArchive, FaFileExcel, FaFileExport} from "react-icons/all";
import FileDownload from "js-file-download";
import moment from "moment";
import Currency from "../../../../components/My/Currency";
import {SET_FILTER} from "../../../../reducers/filters";

const ListOfSubject = (props) => {

    let [data, setData] = useState(null)
    let [loading, setLoading] = useState(null)
    let [loadingExport, setLoadingExport] = useState(false)

    let selectedRef = useRef([]);

    let {billingListOfSubject} = useSelector(state => state.filters)
    let {billingListOfSubjectPageSize} = useSelector(state => state.filters)
    let {billingListOfSubjectPageIndex} = useSelector(state => state.filters)

    const {billingId} = useParams()
    let exportAbra = props?.user?.rights?.billing?.exportAbra
    let exportData = props?.user?.rights?.billing?.export

    let dispatch = useDispatch()

    useEffect(() => {
        get()
    }, [])

    const get = (filter = billingListOfSubject) => {
        setLoading(true)

        apiGetSubjectBillingList(billingId, filter, (data) => {
            setLoading(false)
            setData(data)
        }, err => {
            Toast.warn("Při načítání došlo k chybě.")
            setLoading(false)
        })
    }
    const exportList = (type) => {
        if (selectedRef?.current?.length === 0) {
            Toast.warn("Vyberte řádky.")
            return;
        }

        setLoadingExport(true)
        apiGetExportBilling(billingId, selectedRef.current, type, (data) => {
            if (type === "XLS_LIST") {
                let blob = new Blob([data.data], {type: "application/vnd.ms-excel"});
                FileDownload(blob, data.headers['content-disposition'].replace('attachment; filename=', ''))
            } else if (type === "ABRA_XML" || type === "ABRA_XML_MOBILE") {
                let blob = new Blob([data]);
                FileDownload(blob, "export_abra.xml")
            } else if (type === "ZIP_XLS_PER_SUBJECT") {
                let blob = new Blob([data.data], {type: "application/zip"});
                FileDownload(blob,  'data.zip')
            }
            setLoadingExport(false)
        }, error => {
            Toast.warn("Při načítání došlo k chybě.")
            setLoadingExport(false)
        })
    }

    const getStatus = (status) => {
        if (status === 0) return <span className={"text-orange-600 font-bold"}>Čeká na zpracování</span>
        if (status === 1) return <span className={"text-red-600 font-bold"}>Nelze spočítat</span>
        if (status === 2) return <span className={"text-green-600 font-bold"}>Úspěšně dokončeno</span>
        if (status === 3) return <span className={"text-green-600 font-bold"}>Dokončeno - manuálně</span>
        if (status === 4) return <span className={"text-blue-600 font-bold"}>Neaktivní měsíc</span>
        if (status === 5) return <span className={"text-blue-600 font-bold"}>Vyúčtování po akci manuálně</span>
    }

    const getAbra = (exportAbra) => {
        if (exportAbra) return moment(exportAbra).format("llll")
        return "NE"
    }

    const getBankDifference = (bankDifference, currency) => {
        if (isNaN(bankDifference)) return "Nespočítáno"
        if (bankDifference > 0) return <div className={"text-red-500 font-bold"}><Currency currency={currency}
                                                                                           type={"text"}
                                                                                           price={bankDifference}/>
        </div>
        if (bankDifference < 0) return <div className={"text-red-500 font-bold"}><Currency currency={currency}
                                                                                           type={"text"}
                                                                                           price={bankDifference}/>
        </div>
        return "-"
    }

    const getSubjectname = (name, urlAddress) => {
        return(
            <div className={"flex flex-col"}>
                <div className={"flex font-bold"}>{name}</div>
                <div className={"flex text-sm"}>{urlAddress}</div>
            </div>
        )
    }

    let columns = [
        {Header: 'Subjekt', accessor: d=> getSubjectname(d?.name, d?.urlAddress)},
        {Header: 'Key account', accessor: "keyAccountName"},
        {Header: 'Rozdíl', accessor: d => getBankDifference(d?.billing?.bankDifference, d.currency)},
        {Header: 'Stav', accessor: d => getStatus(d?.billing?.status)},
        {Header: 'V abře', accessor: d => getAbra(d?.billing?.exportAbra)},
        {
            Header: 'K editaci',
            accessor: (d=> <Button text={"Otevřít"} link={"/billing/detail/" + billingId + "/set/" + d.id}/>)
        }]

    const getBody = () => {
        if (loading) return <Loader/>
        return <Datatable data={data} columns={columns} isSelection={true} selectedRef={selectedRef} isPageSize={billingListOfSubjectPageSize}
        setPageCustomSize={setPageCustomSize} isPageIndex={billingListOfSubjectPageIndex} setCustomPageIndex={setCustomPageIndex}/>
    }

    const setPageCustomSize = (size) => {
        dispatch({type: SET_FILTER, page: "billingListOfSubjectPageSize", payload: size})
    }

    const setCustomPageIndex = (size) => {
        dispatch({type: SET_FILTER, page: "billingListOfSubjectPageIndex", payload: size})
    }

    const getButtons = () => {

        let array = []
        if (exportData) {
            array.push(
                <button
                    className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-orange-100"
                    onClick={() => exportList("XLS_LIST")}>
                    <FaFileExcel className={"mr-2"}/><span>Exportovat seznam</span>
                </button>,
                <button
                    className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-orange-100"
                    onClick={() => exportList("ZIP_XLS_PER_SUBJECT")}>
                    <FaFileArchive className={"mr-2"}/><span>Exportovat XLS v ZIP</span>
                </button>
            )
        }
        if (exportAbra) {
            array.push(
                <button
                    className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-orange-100"
                    onClick={() => exportList("ABRA_XML")}>
                    <FaFileExport className={"mr-2"}/><span>Abra klasické</span>
                </button>
            )
            array.push(
                <button
                    className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-orange-100"
                    onClick={() => exportList("ABRA_XML_MOBILE")}>
                    <FaFileExport className={"mr-2"}/><span>Abra mobilní aplikace</span>
                </button>
            )
        }

        return array

    }

    if (!data) return null
    return (
        <div>
            <div className={"flex flex-row"}>
                <span className="ml-auto"></span>

                <Dropdown loading={loadingExport} title={"Možnosti"} width={"w-48"} placement={"bottom-end"}
                          children={getButtons()}/>
            </div>
            <Filter get={get} filter={billingListOfSubject}/>
            {getBody()}
        </div>
    )
}


export default ListOfSubject
