import {instance, onErrorFunction} from "../../config/axios-cnf";

export const apiGetListPayment = (values, callback, error) => {
    instance.get("/api/bank-transaction/list-payment", {params: values})
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiUpdateBankTransaction = (values, callback, error) => {
    instance.post("/api/bank-transaction/list-payment", values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetListOrders = (values, callback, error) => {
    instance.get("/api/bank-transaction/list-order", {params: values})
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
