import React from "react";
import {Routes, Route} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Users from "./pages/Users/Users";
import Test from "./pages/Test/Test";
import Subject from "./pages/Subject/Subject";
import AddSubject from "./pages/Subject/AddSubject";
import AddUser from "./pages/Users/AddUser";
import EditUser from "./pages/Users/EditUser";
import EditSubject from "./pages/Subject/EditSubject";
import Billing from "./pages/Billing/Billing";
import Detail from "./pages/Billing/Detail/Detail";
import SetBilling from "./pages/Billing/Detail/ListOfSubject/SetBilling/SetBilling";
import ListPayment from "./pages/BankInvoices/ListPayment/ListPayment";
import ListOrders from "./pages/BankInvoices/Order/ListOrders";
import {useSelector} from "react-redux";
import MyAccount from "./pages/MyAccount/MyAccount";
import {Daily} from "./pages/Stats/Daily/Daily";
import Config from "./pages/System/Config/Config";
const Routing = () => {

    const navigation = useSelector(state => state.navigation)
    const {user} = useSelector(state => state.user)


    const hasRight = (route) => {
        if(!navigation) return false
        let exist = navigation[0].routes.findIndex(i=>i===route)
        return exist !== -1
    }



    return (
        <Routes>

            <Route path={"/system/users"} element={hasRight("/system/users") ? <Users/> :<Denied/>}/>
            <Route path={"/system/users/add"} element={hasRight("/system/users") ? <AddUser/> : <Denied/>}/>
            <Route path={"/system/users/edit/:id"} element={hasRight("/system/users") ? <EditUser/> : <Denied/>}/>
            <Route path={"/system/subject"} element={hasRight("/system/subject") ? <Subject/> : <Denied/>}/>
            <Route path={"/system/subject/add"} element={hasRight("/system/subject") ? <AddSubject/> : <Denied/>}/>
            <Route path={"/system/subject/edit/:id"} element={hasRight("/system/subject") ? <EditSubject/> : <Denied/>}/>
            <Route path={"/system/config"} element={hasRight("/system/config") ? <Config/> : <Denied/>}/>


            <Route path={"/dashboard"} element={<Dashboard/>} />
            <Route path={"/billing/list"} element={hasRight("/billing/list") ? <Billing user={user}/> : <Denied/>}/>
            <Route path={"/billing/detail/:billingId"} element={hasRight("/billing/list") ? <Detail user={user}/> : <Denied/>}/>
            <Route path={"/billing/detail/:billingId/set/:subjectId"} element={hasRight("/billing/detail/:billingId/set/:subjectId") ? <SetBilling user={user}/> : <Denied/>}/>
            <Route path={"/bank-invoices/list-payment"} element={hasRight("/bank-invoices/list-payment") ? <ListPayment user={user}/> :<Denied/>}/>
            <Route path={"/bank-invoices/list-order"} element={hasRight("/bank-invoices/list-order") ? <ListOrders user={user}/> : <Denied/>}/>
            <Route path={"/test"} element={true ? <Test/> : <Denied/>}/>
            <Route path={"/my-account"} element={<MyAccount user={user}/>}/>
            <Route path={"/stats/daily"} element={hasRight("/stats/daily") ? <Daily/> : <Denied/>}/>

        </Routes>
    );
};

const Denied = () =>{
    return (<div>Přístup zamítnut.</div>)
}

export const hasRight = (wantedRight, rights) =>{

}


export default Routing;
