import React, {useState} from 'react'
import Layout from '../../layouts/centered'
import CenteredForm from '../../layouts/centered-form'
import {Form} from "react-final-form";
import InputField from "../../FinalForm/InputField";
import {apiLoginUser} from "./Actions";
import {useDispatch} from "react-redux";
import Alert from "../../components/alerts";
import {Button} from "../../components/My/Button";
import {useNavigate} from "react-router-dom";
import {USER_SET_VERIFY_TOKEN} from "../../reducers/user";

const LoginPage = () => {

    let [loading, setLoading] = useState(false)
    let [error, setError] = useState(null)
    const navigate = useNavigate();
    let dispatch = useDispatch()

    const onLogin = (values) => {
        setError(null)
        setLoading(true)
        apiLoginUser(values, (data) => {
            setLoading(false)
            dispatch({type:USER_SET_VERIFY_TOKEN, payload: data.verifyToken})
            navigate("/verify");
        }, error => {
            setLoading(false);
            switch (error.type) {
                case "LOGIN_ERROR":
                    setError("Špatné přihlašovací údaje.");
                    break;
                case "USER_NOT_VERIFED":
                    setError("Účet nebyl dosud verifikován. Klidněte na odkaz v e-mailu.");
                    break;
                default:
                    setError("Došlo k chybě")
            }
        })
    }

    return (
        <Layout>
            <CenteredForm subtitle="Prosím přihlašte se">
                {error && <Alert color={"text-red-500"} outlined={true}>{error}</Alert>}
                <Form onSubmit={onLogin}
                      render={({handleSubmit}) => (
                          <form className="form flex flex-wrap w-full">
                              <div className="w-full">
                                  <InputField name={"login"} label={"Přihlašovací jméno"} inputType={"text"}
                                              placeholder={"Přihlašovací jméno"} isMandatory={true}
                                              onEnter={() => handleSubmit()}/>
                              </div>
                              <div className="w-full">
                                  <InputField name={"password"} inputType={"password"} label={"Heslo"}
                                              isMandatory={true} onEnter={() => handleSubmit()}/>
                              </div>
                              <Button onClick={handleSubmit} text={"Přihlásit se"} loading={loading}/>
                          </form>
                      )}
                />
                <div className="flex flex-row w-full mt-3">
                </div>
                <div className="w-full">
          <span>

          </span>
                </div>
            </CenteredForm>
        </Layout>
    )
}

export default LoginPage
