import {instance, onErrorFunction} from "../../config/axios-cnf";
import { USER_SET_TOKEN } from "../../reducers/user";

export const apiLogout = (callback, error) => {
    instance.post("/api/user/logout")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiAuthorizate = (callback, error) => {
    instance.get("/api/user/auth")
        .then(response => callback(response.data))
        .catch(err => error(err))
}

export const apiLoginUser = (values, callback, error) => {
    instance.post("/api/user/login", values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiVerifyUser = (values, callback, error) => {
    instance.post("/api/user/verify", values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const axiosSetAuthorizationToken = (token, dispatch) => {
    instance.defaults.headers.common["authorization"] = token;
    localStorage.setItem("authorization-turn-enigoo", token)
    dispatch({ type: USER_SET_TOKEN, payload: token })
}


export const getTokenFromLocalStorage = (dispatch) => {
    let token = localStorage.getItem('authorization-turn-enigoo')
    if (token === null || token === 'undefined') return false;
    axiosSetAuthorizationToken(token, dispatch)
}




