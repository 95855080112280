import React from "react"
import {Form} from "react-final-form";
import CheckboxField from "../../../../FinalForm/CheckboxField";
import InputField from "../../../../FinalForm/InputField";
import {SET_FILTER} from "../../../../reducers/filters";
import {useDispatch} from "react-redux";
import UsersField from "../../../../FinalForm/api/UsersField";
import SelectField from "../../../../FinalForm/SelectField";

export const Filter = ({get,filter}) => {

    let dispatch = useDispatch()

    const getData = (values) => {
        dispatch({type: SET_FILTER, page: "billingListOfSubject", payload: values})
        dispatch({type: SET_FILTER, page: "billingListOfSubjectPageIndex", payload: 0})
        get(values)
    }

    return(
            <Form onSubmit={getData} initialValues={filter} render={({handleSubmit}) => (
                <>
                    <div className={"flex flex-wrap"}>
                        <div className={"lg:w-1/4 md:w-1/2 w-full"}>
                            <InputField name={"subjectName"} label={""} isMandatory={false}
                                placeholder={"Subjekt"} onEnter={handleSubmit}/>
                        </div><div className={"lg:w-1/4 md:w-1/2 w-full"}>
                            <SelectField name={"status"} options={[
                                {label: "Čeká na zpracování", value: 0},
                                {label: "Nelze dokončit", value: 1},
                                {label: "Úspěšně dokončeno", value: 2},
                                {label: "Dokončeno - import do abr. manuálně", value: 3},
                                {label: "Neaktivní měsíc", value: 4},
                                {label: "Vyúčtování po akci manuálně", value: 5}
                            ]} change={handleSubmit} />
                        </div>
                        <div className={"lg:w-1/4 md:w-1/2 w-full p-2"}>
                            <CheckboxField name={"notExported"} label={"Neexportované do abry"} isMandatory={false} change={handleSubmit} />
                        </div>
                        <div className={"lg:w-1/4 md:w-1/2 w-full p-2"}>
                            <CheckboxField name={"haveMobileApp"} label={"Má mobilní aplikaci"} isMandatory={false} change={handleSubmit} />
                        </div>
                        <div className={"flex lg:w-2/4 md:w-1/2 w-full"}>
                            <UsersField name={"keyAccountId"} label={""} isMulti={true} change={handleSubmit}
                                        placeholder={"Vyberte account m."} filters={{onlyKeyAccount:true}} />
                        </div>
                        <div className={"lg:w-1/4 md:w-1/2 w-full p-2"}>
                            <CheckboxField name={"bankDifference"} label={"Rozdíl na bance"} isMandatory={false} change={handleSubmit} />
                        </div>
                        <div className={"lg:w-1/4 md:w-1/2 w-full p-2"}>
                            <CheckboxField name={"closedSubject"} label={"Zobrazit neaktivní"} isMandatory={false} change={handleSubmit} />
                        </div>
                    </div>
                </>
            )}/>
    )
}
