import React, {useEffect, useState} from "react"
import TableData from "./TableData";
import {Button} from "../../components/My/Button";
import {apiGetUsers} from "./Actions";
import Header from "../../components/My/Header";
import Toast from "cogo-toast";

const Users = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)

    useEffect(() => {
        getUser()
    }, [])

    const getUser = () => {
        setLoading(true)
        apiGetUsers(null, (data) => {
            setData(data)
            setLoading(false)
        }, (err) => {
            Toast.warn("Došlo k neočekávané chybě.")
            setLoading(false)
        })
    }

    return (
        <Header data={[
            {text: "Uživatelé", isMain: true}
        ]} rightContent={<div className={"flex"}><Button text={"Přidat uživatele"} link={"/system/users/add"}/></div>}>
            <TableData data={data} loading={loading}/>

        </Header>
    )

}

export default Users
