import React, {useState} from "react";
import Modal from "../../components/My/Modal";
import {Button} from "../../components/My/Button";
import {Form} from "react-final-form";
import Toast from "cogo-toast";
import CheckboxField from "../../FinalForm/CheckboxField";
import {apiChangeBillingListItem} from "./Actions";


const ModalEditBilling = ({close, visible}) => {

    let [loading, setLoading] = useState(false)
    let submit

    let item = visible

    const change = (values) => {
        setLoading(true)
        apiChangeBillingListItem(item.id, values, (data) => {
            close(true)
            setLoading(false)
            Toast.success("Dokončeno.")
        }, error => {
            setLoading(false)
            Toast.warn("Došlo k chybě.")
        })
    }

    const getBody = () => {
        return (
            <Form onSubmit={change} initialValues={{open: item.open, future: item.future, hidden: item.hidden}} render={({handleSubmit}) => {
                submit = handleSubmit
                return (
                    <div className={"flex flex-wrap w-full"}>
                        <div className={"w-1/2"}>
                            <CheckboxField label={"Lze vyúčtování editovat"} name={"open"}/>
                        </div>
                        <div className={"w-1/2"}>
                            <CheckboxField label={"Aktuální měsíc - nelze vyúčtovat"} name={"future"}/>
                        </div>
                        <div className={"w-1/2"}>
                            <CheckboxField label={"Skryto"} name={"hidden"}/>
                        </div>
                    </div>
                )
            }}/>
        )
    }


    if (!item) return null
    return (
        <>
            <Modal body={getBody()} title={"Upravit vyúčtování"} visible={visible} close={() => close(false)}
                   footer={
                       <>
                           <Button outline={true} text={"Zavřít"} onClick={() => close()}/>
                           <Button text={"Potvrdit"} onClick={() => submit()} loading={loading}/>
                       </>
                   }
            />
        </>
    )
}

export default ModalEditBilling
