import React, {useEffect, useState} from "react";
import {Button} from "../../../components/My/Button";
import Header from "../../../components/My/Header";
import {apiGetListPayment} from "../Actions";
import Loader from "../../../components/My/Loader";
import Datatable from "../../../components/datatable";
import Widget from "../../../components/widget";
import Currency from "../../../components/My/Currency";
import ModalDetailPayment from "./ModalDetailPayment";
import Filter from "./Filter";
import {getDate} from "../../../components/My/DateFormat";
import {useDispatch, useSelector} from "react-redux";
import {SET_FILTER} from "../../../reducers/filters";


const ListPayment = () => {


    let [data, setData] = useState(null)
    let [loading, setLoading] = useState(false)
    let [modalDetail, setModalDetail] = useState(false)

    let {listOfPaymentSummary, listOfPaymentSummaryPageSize} = useSelector(state => state.filters)
    let dispatch = useDispatch()

    useEffect(() => {
        get()
    }, [])

    const get = (filters = listOfPaymentSummary) => {
        setLoading(true)
        apiGetListPayment(filters,(data) => {
            setData(data)
            setLoading(false)
        }, error => {
            setLoading(false)
        })
    }

    const getStatus = (status) => {
        switch (status){
            case 0: return <div>Čeká na zaplacení.</div>
            case 1: return <div className={"text-red-500 font-bold"}>Došlo k chybě.</div>
            case 2: return <div className={"text-green-500 font-bold"}>Dokončeno.</div>
        }
    }

    const getDates = (d) => {
      return (
          <div className={"flex flex-col"}>
              <div className={"flex"}>{getDate(d.dateAddToBank)}</div>
              <div className={"flex"}>{getDate(d.dateInsert)}</div>
          </div>
      )
    }

    let columns = [
        {Header: '#', accessor: "id"},
        {Header: 'Banka', accessor: "bank.name"},
        {Header: 'VS', accessor: "vs"},
        {Header: 'Datum připsání/stažení', accessor: d => getDates(d)},
        {Header: 'Cena', accessor: d => <Currency type={"text"} price={d.price}/>},
        {Header: 'Subjekt', accessor: "subject.name"},
        {
            Header: 'Stav transakce',
            accessor: d => getStatus(d.status)
        },
        {Header: 'Možnosti', accessor: d => <Button text={"Detail"} onClick={() => setModalDetail(d)}/>}
    ]
    const getBody = () => {
        if (loading) return <Loader/>
        if (data) {
            return <Widget><Datatable data={data} columns={columns} isPageSize={listOfPaymentSummaryPageSize} setPageCustomSize={setPageCustomSize}/></Widget>
        }
    }

    const setPageCustomSize = (size) => {
        dispatch({type: SET_FILTER, page: "listOfPaymentSummaryPageSize", payload: size})
    }


    return (
        <Header data={[{text: "Fakturace banka - list plateb", isMain: true}]}>
            <Filter get={get} loading={loading} filter={listOfPaymentSummary}/>
            {getBody()}
            <ModalDetailPayment visible={modalDetail} close={(renew)=>
            {
                setModalDetail(null)
                renew && get()
            }} />
        </Header>
    )
}

export default ListPayment
