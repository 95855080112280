import React from "react";


const Loader = () => {

    return(
        <div className={"text-center w-full"}>Loading...</div>
    )
}

export default Loader
