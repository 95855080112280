import React from "react"

import Loader from "../../../../components/My/Loader";
import Currency from "../../../../components/My/Currency";

const Basic = ({data, loading}) => {

    if (loading) return <Loader/>


    const getStatus = () => {
        if (data.future) return <div className={"text-base text-red-500 p-3 font-bold"}>Vyučtování nelze dokončit z důvodu, že není možné porovnat banku. Nahrajte výpisy a povolte editaci.</div>
        if (!data.open) return <div className={"text-base text-red-500 p-3 font-bold"}>Vyučtování je uzavřeno, nelze ho již měnit. </div>
    }


    const getItem = (title, count) => {
      return(
          <div className="w-full lg:w-1/5">
              <div className="widget w-full p-4 rounded-lg bg-white border border-gray-100 dark:bg-gray-900 dark:border-gray-800">
                  <div className="flex flex-row items-center justify-between">
                      <div className="flex flex-col">
                          <div className="text-xs uppercase font-light text-gray-500">{title}</div>
                          <div className="text-xl font-bold">{count}</div>
                      </div>
                  </div>
              </div>
          </div>
      )
    }

    const getSummaryOfBilling = () => {
        return (
            <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
                {getItem("Celkem subjektů", data?.data?.summary?.subjectCount)}
                {getItem("Dokončeno", data?.data?.summary?.done)}
                {getItem("Manuálně", data?.data?.summary?.manual)}
                {getItem("Neaktivní", data?.data?.summary?.nonActive)}
                {getItem("Nelze spočítat", data?.data?.summary?.notPossible)}
                {getItem("Čeká", data?.data?.summary?.waiting)}

            </div>
        )
    }


    return (
        <div className={"flex flex-col"}>
            {getStatus()}
            {getSummaryOfBilling()}
            <div className={"flex flex-row"}>
                <div className={"flex flex-col lg:w-1/2 w-full"}>
                    <h4>Dle měny CZK</h4>
                    <div className={"text-base font-bold"}>Aktuální provize: <Currency currency={"CZK"} type={"text"} price={data?.data?.byCurrency?.CZK?.commision} /></div>
                    <div className={"text-base font-bold"}>Manipulační poplatky: <Currency currency={"CZK"} type={"text"} price={data?.data?.byCurrency?.CZK?.manipulation} /></div>
                </div>

                <div className={"flex flex-col lg:w-1/2 w-full"}>
                    <h4>Dle měny EUR</h4>
                    <div className={"text-base font-bold"}>Aktuální provize: <Currency currency={"EUR"} type={"text"} price={data?.data?.byCurrency?.EUR?.commision} /></div>
                    <div className={"text-base font-bold"}>Manipulační poplatky: <Currency currency={"EUR"} type={"text"} price={data?.data?.byCurrency?.EUR?.manipulation} /></div>
                </div>


            </div>

        </div>
    )




}

export default Basic
