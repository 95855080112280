import React, {useEffect, useState} from "react"
import Header from "../../../components/My/Header";
import Widget from "../../../components/widget";
import InputField from "../../../FinalForm/InputField";
import {Form} from "react-final-form";
import Loader from "../../../components/My/Loader";


const Config = () => {

    let [data, setData] = useState(null)
    let [loading, setLoading] = useState(false)

    useEffect(()=>{
        getConfig()
    },[])

    const getConfig = () => {
        setLoading(true)
    }

    const save = (values) => {

    }

    const init = () =>{
        if(data) return data
        return null
    }

    if(loading) return <Loader/>

    return (
        <Header data={[{text: "Systém"}, {text: " Nastavení", isMain: true}]}>
            <Widget>
                <Form onSubmit={save} initialValues={init} render={({handleSubmit, values}) => {
                    return (
                        <div>
                            <div className={"flex flex-wrap w-full"}>
                                <div className={"w-1/2"}>
                                    <InputField label={"Logovací e-mail"} name={"email"} isMandatory={true}/>
                                </div>
                                <div className={"w-1/2"}>
                                    <InputField label={"Jméno uživatele"} name={"name"} isMandatory={true}/>
                                </div>
                            </div>
                        </div>)
                }}
                />
            </Widget>
        </Header>
    )
}

export default Config
