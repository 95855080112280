import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTokenFromLocalStorage } from "./pages/LoginPage/Actions";
import Auth from "./pages/LoginPage/Auth";

import './css/tailwind.css'
import './css/animate.css'
import './css/main.css'
import './css/_components.css'
import LoginRouter from "./pages/LoginPage/LoginRouter";


const Token = () => {
    let [done, setDone] = useState(false);


    const { token, user } = useSelector((state) => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        getTokenFromLocalStorage(dispatch);
        setDone(true);
    }, []);

    if (!done) return null;

    if (!token) return <LoginRouter />;

    return <Auth user={user}/>;
};

export default Token;
