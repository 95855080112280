export const SET_FILTER = "SET_FILTER";
export const RESET_FILTER = "RESET_FILTER";

export default function reducer(
    state = {
        filters: {},
    },
    action
) {
    switch (action.type) {
        case SET_FILTER: {
            return {
                ...state,
                [`${action.page}`]: action.payload
            }
        }
        case RESET_FILTER:
            return {...state, filters: {}}
        default:
            return state

    }
}

