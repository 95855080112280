import Currency from "../../../../../components/My/Currency";
import React from "react";


const getValue = (values) =>{
    if(values){
        return values
    }
    return 0
}


export const getRowBlock = (text, canal, type, billingData, data) => {
    return (
        <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
            <div className={"w-48 justify-center pl-1"}>
                {text}
                <div>{getValue(billingData.byType?.[type]?.[canal]?.countTicket)} ks</div>
            </div>
            <div className={"w-32 p-1"}>
                <div className={"font-bold"}>Částka</div>
                <div><Currency currency={data?.subject?.currency} type={"text"}
                               price={getValue(billingData?.byType?.[type]?.[canal]?.totalPrice)}/></div>
            </div>
            <div className={"w-32 p-1"}>
                <div className={"font-bold"}>
                    % provize
                </div>
                <div className={"font-bold h-8 align-middle"}>{getValue(billingData?.byType?.[type]?.[canal]?.commPercent)} %</div>
            </div>
            <div className={"w-32 p-1"}>
                <div className={"font-bold"}>
                    ks provize
                </div>
                <div
                    className={"font-bold h-8 align-middle"}>{getValue(billingData?.byType?.[type]?.[canal]?.commByKs)} {getCurrency(data)}</div>
            </div>
            <div className={"w-32 p-1"}>
                <div className={"font-bold"}>
                    EN provize
                </div>
                <div><Currency currency={data?.subject?.currency} type={"text"}
                               price={getValue(billingData.byType?.[type]?.[canal]?.enigooCommTotal)}/></div>
            </div>
            <div className={"w-48 p-1"}>
                <div className={"font-bold"}>
                    Vratky z předchozích obd.
                </div>
                <div><Currency currency={data?.subject?.currency} type={"text"}
                               price={getValue(billingData?.byType?.[type]?.[canal]?.returnTotal)}/></div>
            </div>
        </div>
    )
}

export const getCurrency = (data) => {
    if (data?.subject?.currency === "EUR") return "EUR/ks"
    if (data?.subject?.currency === "CZK") return "Kč/ks"

    return "Kč/ks"
}


export const getRowWithText = (text, amount, data) => {
    return (
        <div className={"flex flex-row pb-1 pt-1"}>
            <div className={"w-1/2 justify-center pl-1 font-bold"}>{text}</div>
            <div className={"w-1/2"}>
                <div className={"text-base"}>
                    <Currency currency={data?.subject?.currency} type={"text"} price={amount}/>
                </div>
            </div>
        </div>
    )
}
