import React from "react"
import InputField from "../../../FinalForm/InputField";
import CheckboxField from "../../../FinalForm/CheckboxField";

const OtherCommision = (props) => {
  return(
      <>
          <span className={"w-full text-base font-bold mt-3"}>Ostatní</span>
          <div className={"w-full flex flex-wrap bg-grey-100 mt-1 p-2 rounded"}>
              <div className={"flex w-1/4"}>
                  <InputField label={"Vstupenky SAZKA %"} name={"other.sazka"} inputType={"number"}
                              isMandatory={true} min={0} step={'0.01'}/>
              </div>
              <div className={"flex w-1/4"}>
                  <InputField label={"Bankovní poplatky"} name={"other.bankFee"} inputType={"number"}
                              isMandatory={true} min={0} step={'0.01'}/>
              </div>
              <div className={"flex w-1/4"}>
                  <InputField label={"Bankovní poplatky - Fakturace (FIO)"} name={"other.invoiceFee"}
                              inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
              </div>
              <div className={"flex w-1/4"}>
                  <InputField label={"Manipulační poplatek/vstupenka"} name={"other.manipulationFee"}
                              inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
              </div>
              <div className={"flex w-1/4"}>
                  <InputField label={"Minimální plnění"} name={"other.minimalPrice"} inputType={"number"}
                              isMandatory={true} min={0} step={'0.01'}/>
              </div>
              <div className={"flex w-1/4"}>
                  <InputField label={"Měsíční fixace (+ provize)"} name={"other.monthFix"}
                              inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
              </div>
              <div className={"flex w-1/4"}>
                  <InputField label={"Maximální roční plnění"} name={"other.yearFix"} inputType={"number"}
                              isMandatory={true} min={0} step={'0.01'}/>
              </div>

              <div className={"flex w-1/4"}>
                  <InputField label={"Měsíční paušál"} name={"other.monthFlatRate"} inputType={"number"}
                              isMandatory={true} min={0} step={'0.01'}/>
              </div>

              <div className={"flex w-full ml-1"}>
                  <CheckboxField name={"other.specialBilling"}
                                 label={"Speciální výpočet, zatím nelze automaticky"}/>
              </div>
              <div className={"flex w-full ml-1"}>
                  <InputField label={"Speciální výpočet - poznámka"} name={"other.specialBillingNote"} inputType={"textarea"} isMandatory={false}/>
              </div>
              <div className={"flex w-full ml-1"}>
                  <CheckboxField name={"other.postageMakeSubject"}
                                 label={"Poštovné si řeší subjekt (není na faktuře)"}/>
              </div>
              <div className={"flex w-full ml-1"}>
                  <CheckboxField name={"other.commisionFromSalesWithoutDph"} label={"Provize se počítá z částky prodejů bez DPH"}/>
              </div>
              <div className={"flex w-full ml-1"}>
                  <CheckboxField name={"other.commisionFromSalesWithoutVoucher"} label={"Provize bez částky prodejů, kde je uplatněn voucher"}/>
              </div>
          </div>
      </>
  )
}

export default OtherCommision
