import React, {useEffect, useState} from "react"
import Widget from "../../components/widget";


const Dashboard = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)


    useEffect(() => {
        get()
    }, [])

    const get = () => {
       /* setLoading(true)
        apiGetDashboard((data) => {
            setData(data)
            setLoading(false)
        }, error => {
            setLoading(false)
        })*/
    }

    if (loading) return <div>Loader</div>
    if (!data) return null
    const body = () => data.map(item => {
        return (
            <Widget>

            </Widget>
        )
    })

    return (
        <div>
            {body()}
        </div>
    )

}

export default Dashboard
