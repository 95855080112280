import React from "react";
import {getRowBlock, getRowWithText} from "./DataComponents";
import Currency from "../../../../../components/My/Currency";

const MobileApplication = (props) => {

    const getBankFee = () => {
        return (
            <div>
                <h5 className={"font-bold"}>Bankovní poplatky</h5>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>ČSOB</div>
                    <div className={"w-64 p-1"}>
                        <div>Prodej online</div>
                        <div className={"text-base"}>
                            <Currency currency={props?.subject?.currency} type={"text"} price={props?.billingData?.total?.mobileCredit?.totalPrice}/>
                        </div>
                    </div>
                    <div className={"w-48 p-1 flex flex-col"}>
                        <div>% Provize</div>
                        <div className={"font-bold"}>{props.billingData?.total?.mobileCredit?.commPercent}</div>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div className={"font-bold"}>Bankovní poplatky ČSOB na faktuře</div>
                        <div className={"font-bold"}>
                            <Currency currency={props?.subject?.currency} type={"text"} price={props.billingData?.total?.mobileCredit?.enigooComm}/>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    const getSummary = () => {
        return (
            <div className={"flex flex-row mt-4 text-base"}>
                <div className={"flex flex-col w-1/2"}>
                    <h5 className={"font-bold"}>Řádky na faktuře</h5>

                    {getRowWithText("Provize enigoo", parseFloat(props?.billingData?.total?.totalCommisionMobileEnigoo))}
                    {getRowWithText("Bankovní poplatky", parseFloat(props?.billingData?.total?.mobileCredit?.enigooComm))}
                    {getRowWithText("Zálohový list", props?.billingData?.total?.totalZLVMobile, props)}

                </div>
            </div>
        )
    }

    return (
        <div className={"flex flex-col"}>
            <div className={"flex flex-row"}>
                {/*<span className="ml-auto"></span>
                <Button text={"Stáhnout excel"}  extraStyle={"mr-1"}/>
                <Button text={"Importovat do abry"} />*/}
            </div>
            <div className={"flex flex-col"}>
                <div className={"font-bold text-base mb-1"}>Mobilní aplikace prodeje</div>
                {getRowBlock("vstupenky", "mobile", "tickets", props.billingData, props)}
                {getRowBlock("permanentky", "mobile", "seasonTicket", props.billingData, props)}

                {getRowBlock("merch", "merch", "mobileCredit", props.billingData, props)}
                {getRowBlock("přání/gratulace", "congrats", "mobileCredit", props.billingData, props)}

                <div className={"font-bold text-base mt-4"}>Mobilní aplikace dobití kreditu</div>
                {getRowBlock("dobití mob. aplikace", "online", "mobileCredit", props.billingData, props)}


                {getBankFee()}

                {getSummary()}
            </div>
        </div>
    )
}
export default MobileApplication
