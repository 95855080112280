import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";

const CheckboxField = (props) => {

    // todo

    /*const errorMessage = (message) => {
        return <span style={{color: "red", marginRight: "10px"}}><i className="fa fa-arrow-up"> </i> {message}</span>;
    }

    const renderError = (_props) => {
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return "Povinné pole"
        }
        return null;
    }

    const getParse = (value) => {
        return value
    }*/

    /*return (
        <>
        <Field
            name={name}
            component="input"
            type="checkbox"
            className="custom-control-input"
            id={name}
        />
            <label htmlFor={name} className={"ml-1 mr-1 cursor-pointer text-base"}>{label}</label>
        </>
    )*/

    return (
        <Field name={props.name} render={(_props) => {
            return (
                <>
                    <input type={"checkbox"} id={props.name} name={props.name} onChange={(e) => {
                        _props.input.onChange(e.target.value)
                        if (props.onChange) {
                            props.onChange(props.name, e.target.value);
                        }
                    }
                    }/>
                    <label htmlFor={props.name} className={"ml-1 mr-1 cursor-pointer text-base"}>{props.label}</label>
                </>
            )
        }
        }/>
    )
}

CheckboxField.defaultProps = {
    inline: true,
    isMandatory: false
}

CheckboxField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inline: PropTypes.bool
}

export default CheckboxField
