import React, {useEffect, useState} from "react";
import Widget from "../../components/widget";

import {apiUpdateUser, apiGetUsers} from "./Actions";
import UserForm from "./UserForm";
import {useParams} from "react-router-dom";
import Loader from "../../components/My/Loader";
import Header from "../../components/My/Header";
import Toast from "cogo-toast";

const EditUser = () => {

    let [loading, setLoading] = useState(false)
    let [loadingButton, setLoadingButton] = useState(false)
    let [data, setData] = useState(null)

    const {id} = useParams()

    useEffect(() => {
        get()
    }, [])

    const get = () => {
        setLoading(true)
        apiGetUsers(id, (data) => {
            setData(data)
            setLoading(false)
        }, (err) => {
            console.log(err)
        })
    }

    const save = (values) => {
        setLoadingButton(true)
        apiUpdateUser(id, values, (data) => {
            setLoadingButton(false)
            get()
            Toast.success("Úspěšně dokončeno.")
        }, error => {
            switch (error?.type){
                case "USER_EXIST":
                    Toast.warn("Uživatelské jméno již existuje.")
                    break;
                default:
                    Toast.warn("Došlo k neočekávané chybě.")
            }
            setLoadingButton(false)
        })
    }


    if (loading) return <Loader/>
    if (!data) return null
    if (data) {
        return (
            <Header data={[
                {text: "Uživatelé", link: "/system/users"},
                {text: "Upravit uživatele", isMain: true}
            ]}>
                <Widget>
                    <UserForm acceptFunction={save} init={data[0]} loading={loadingButton}/>
                </Widget>
            </Header>
        )

    }


}

export default EditUser
