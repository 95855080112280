import {instance, onErrorFunction} from "../../config/axios-cnf";


export const apiGetUsers = (userId, callback, error) => {
    instance.get("/api/system/user", {params:{userId}})
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiCreateUser = (values, callback, error) => {
    instance.put("/api/system/user", values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiUpdateUser = (id, values, callback, error) => {
    instance.post("/api/system/user/" + id, values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
