import React from "react"
import {getDate} from "../../../components/My/DateFormat";
import Currency from "../../../components/My/Currency";
import Datatable from "../../../components/datatable";


const Table = ({data}) => {

    const getStatus = (status) => {
        switch (status) {
            case 0:
                return <div>Čeká na zaplacení.</div>
            case 1:
                return <div className={"text-red-500 font-bold"}>Došlo k chybě.</div>
            case 2:
                return <div className={"text-green-500 font-bold"}>Úspěšně dokončeno.</div>
            default:
                return <div className={"text-green-500 font-bold"}></div>
        }
    }

    let columns = [
        {Header: 'VS', accessor: d => <a className={"underline"} href={d.enigooUrl + "admin/#/orders/detail/" + d.enigooOrderId} target={"_blank"}>{d.id}</a>},
        {Header: 'Datum vytvoření', accessor: d => getDate(d.dateCreate)},
        {Header: 'Datum zaplacení', accessor: d => d.datePaid && getDate(d.datePaid)},
        {Header: 'Cena', accessor: d => <Currency type={"text"} price={d.price}/>},
        {Header: 'Enigoo URL', accessor: "enigooUrl"},
        {Header: 'Enigoo ID', accessor: "enigooOrderId"},
        {
            Header: 'Stav objednávky',
            accessor: d => getStatus(d.status)
        }
    ]

    return(
        <Datatable data={data} columns={columns}/>
    )
}

export default Table
