import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";

const InputField = (props) => {


    const renderError = (meta, value) => {

        if (meta.error && meta.touched) {

            if (!value || value === "") {
                return <div className={"text-red-500"}>Povinné pole</div>;
            }
        }
        if(props.checkWithValue && value !== props.checkWithValue) {
            return <div className={"text-red-500"}>Hesla se neshodují.</div>;
        }
        if (value && props.minLength && value.length < props.minLength) {
            return <div className={"text-red-500"}>Zadejte min. {props.minLength} znaků.</div>;
        }
        if(value && props.maxLength && value.length>props.maxLength){
            return <div className={"text-red-500"}>Lze zadat max. {props.maxLength} znaků.</div>;
        }
        return null;
    };

    const _renderClassName = (meta, value) => {
        if(props.disabled) return 'form-input w-full text-sm ';
        let className = `form-input w-full text-sm `;
        if (meta.touched && meta.error) {
            className += " border-red-500 ";
        } else if (value && props.minLength && value.length < props.minLength) {
            className += " border-red-500 ";
        } else if (value && props.maxLength && value.length > props.maxLength) {
            className += " border-red-500 ";
        } else if(props.checkWithValue && value !== props.checkWithValue){
            className += " border-red-500 ";
        } else if (value) {
            className += " border-green-500 ";
        }

        return className;
    };

    return (
        <Field
            name={props.name}
            validate={(value) => {
                if (props.isMandatory) {
                    if (!value && value !== 0) return true;
                }
                if(props.minLength &&value && value.length < props.minLength) return true;
                if(props.maxLength &&value && value.length > props.maxLength) return true;

            }}
            render={(_props) => {
                return (
                    <div className={"w-full text-sm text-gray-900 p-1"}>
                        {props.label && (
                            <div className="form-label font-bold flex flex-col">
                                <span>{props.label+": "}{props.minLength ? <span className={"text-sm"}>(minimálně {props.minLength} znaků)</span> : null}
                                {props.isMandatory ? <sup className={"text-legendyColor"}>*</sup> : ""}</span>
                                {props.note && <span className={"text-sm font-normal"}>{props.note}</span>}
                            </div>

                        )}

                        {props.inputType === "textarea" ?
                            <textarea
                                placeholder={props.placeholder ? props.placeholder : "Zadejte"}
                                className={_renderClassName(_props.meta)}
                                disabled={props.disabled}
                                value={_props.input.value}
                                maxLength={props.maxLength ? props.maxLength : 20000}
                                onChange={(e) => {
                                    _props.input.onChange(e.target.value);
                                    if (props.onChange) {
                                        props.onChange(props.name, e.target.value);
                                    }
                                }}
                                id={props.id}
                                onKeyDown={({key}) => {
                                    if (key === "Enter") {
                                        if (props.onEnter) props.onEnter();
                                    }
                                }}
                            /> :
                            <input placeholder={props.placeholder ? props.placeholder : "Zadejte"}
                                   type={props.inputType}
                                   className={_renderClassName(_props.meta, _props.input.value)}
                                   disabled={props.disabled}
                                   maxLength={props.maxLength ? props.maxLength : 20000}
                                   value={_props.input.value}
                                   onChange={(e) => {
                                       _props.input.onChange(e.target.value);
                                       if (props.onChange) {
                                           props.onChange(props.name, e.target.value);
                                       }
                                   }}
                                   id={props.id}
                                   onKeyDown={({key}) => {
                                       if (key === "Enter") {
                                           if (props.onEnter) props.onEnter();
                                       }
                                   }}
                            />
                        }
                        <div className={"text-sm pt-1"}> {renderError(_props.meta, _props.input.value)} </div>
                    </div>
                );
            }}
        />
    );
};

InputField.defaultProps = {
    inputType: "text",
    disabled: false
};

InputField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inputStyle: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    showError: PropTypes.bool,
    mandatory: PropTypes.bool,
    isMandatory: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    dataTest: PropTypes.string,
    onEnter: PropTypes.func,
    id: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    inputType: PropTypes.oneOf(["text", "number", "password", "radio", "textarea"]),
    note: PropTypes.string
};

export default InputField;
