import React, {useEffect, useState} from "react";
import SubjectForm from "./SubjectForm";
import {apiGetSubject, apiUpdateSubject} from "./Actions";
import Toast from "cogo-toast";
import Header from "../../components/My/Header";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/My/Loader";
import {IconTabs} from "../../components/tabs";
import Bank from "./Bank/Bank";
import Commision from "./Commision/Commision";
import Market from "./Market/Market";
import {SET_FILTER} from "../../reducers/filters";
import {useDispatch, useSelector} from "react-redux";

const EditSubject = () => {

    let [loading, setLoading] = useState(false)
    let [loadingButton, setLoadingButton] = useState(false)
    let [data, setData] = useState(null)
    const {id} = useParams()
    let {subjectEditDetailTabIndex} = useSelector(state => state.filters)

    let navigate = useNavigate()
    let dispatch = useDispatch()


    useEffect(() => {
        get()
    }, [])

    const get = () => {
        setLoading(true)
        apiGetSubject(id,null, (data) => {
            setData(data[0])
            setLoading(false)
        }, (err) => {
            switch (err?.type) {
                case "SAME_ADDRESS_EXIST":
                    Toast.warn("Url adresa subjektu existuje u jiného subjektu.")
                    break;
                case "SUBJECT_NOT_FOUND":
                    Toast.warn("Subjekt neexistuje.")
                    navigate("/subject")
                    break;
                default:
                    console.log(err)
                    Toast.warn("Došlo k neočekávané chybě.")
            }
            setLoading(false)
        })
    }
    const save = (values) => {
        setLoadingButton(true)
        apiUpdateSubject(id, values, (data) => {
            setLoadingButton(false)
            Toast.success("Dokončeno")

        }, (error) => {
            setLoadingButton(false)
            switch (error?.type) {
                case "SAME_ADDRESS_EXIST":
                    Toast.warn("Ulr adresa subjektu existuje u jiného subjektu.")
                    break;
                case "SUBJECT_NOT_EXIST":
                    Toast.warn("Subjekt neexistuje.")
                    break;
                default:
                    Toast.warn("Došlo k neočekávané chybě.")
            }
        })
    }

    const setCacheIndex = (index) =>{
        dispatch({type: SET_FILTER, page: "subjectEditDetailTabIndex", payload: index})
    }

    const getBody = () => {
        if (loading) return <Loader/>
        if (!data) return null
        if (data) {
            return (
                <IconTabs setCacheIndex={setCacheIndex} defaultIndex={subjectEditDetailTabIndex} tabs={[
                    {
                        index: 0,
                        title: <div>Základní informace</div>,
                        content: <SubjectForm acceptFunction={save} init={data} loading={loadingButton}/>

                    },
                    {
                        index: 1,
                        title: <div>Banky</div>,
                        content: <Bank data={data} renew={get}/>
                            },
                    {
                        index: 2,
                        title: <div>Provize a vyúčtování</div>,
                        content: <Commision data={data} renew={get}/>
                    },
                    {
                        index: 3,
                        title: <div>Market ENIGOO</div>,
                        content: <Market data={data} renew={get}/>
                    }
                ]} />
            )
        }
    }

    const getName = () => {
      if(data){
          return " (" + data.name +")"
      }
      return ""
    }

    return (
        <Header data={[
            {text: "Subjekty", link: "/system/subject"},
            {text: "Upravit subjekt" + getName(), isMain: true},
        ]}>
           {getBody()}
        </Header>

    )
}

export default EditSubject
