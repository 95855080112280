import React, {useEffect, useState} from "react";
import {Button} from "../../../../components/My/Button";
import {Field, Form} from "react-final-form";
import {apiGetBankPayment, apiPostImportPaymentGatewayFile} from "../../Actions";
import Currency from "../../../../components/My/Currency";
import Loader from "../../../../components/My/Loader";
import Datatable from "../../../../components/datatable";
import Toast from "cogo-toast";
import FileDownload from "js-file-download";


const PaymentGateway = (props) => {


    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)
    let [loadingButton, setLoadingButton] = useState(false)
    let [file, setFile] = useState(null)


    useEffect(() => {
        get()
    }, [])


    const get = (type = "JSON") => {
        if (type === "JSON") setLoading(true)
        if (type === "XLS_LIST") setLoadingButton(true)
        apiGetBankPayment(props.billingId, type, (data) => {
            if (type === "XLS_LIST") {
                let blob = new Blob([data.data], {type: "application/vnd.ms-excel"});
                FileDownload(blob, data.headers['content-disposition'].replace('attachment; filename=', ''))
                setLoadingButton(false)
            } else {
                setData(data)
                setLoading(false)
            }
        }, err => {
            setLoading(false)
        })
    }

    const importData = async (values) => {
        setLoadingButton(true)

        const formData = new FormData();
        formData.append('file', file);

        apiPostImportPaymentGatewayFile(props.billingId, values.bankId, formData, (data) => {
            get()
            setLoadingButton(false)
        }, error => {
            switch (error?.type) {
                case "BILLING_NOT_EXIST":
                    Toast.warn("Vyúčtování neexistuje.");
                    break;
                case "BANK_NOT_EXIST":
                    Toast.warn("Banka neexistuje.");
                    break;
                case "BAD_BANK_PAYMENT_MONTH":
                    Toast.warn("Výpis, který nahráváte je z jiného měsíce.");
                    break;
                case "IMPORT_FAIL":
                    Toast.warn("Při importu došlo k chybě.");
                    break;
                case "BAD_BANK_CURRENCY":
                    Toast.warn("Výpis je ve špatné měně.");
                    break;
                default:
                    Toast.warn("Při importu došlo k chybě.");
                    break;

            }

            setLoadingButton(false)
        })
    };

    const _importComponent = (item) => {
        if (item.data?.onlineSales !== 0 || item.data?.onlineReturn !== 0) return null
        return (
            <>
                <div className={"text-base text-red-400 font-bold"}>Nebyla naimportována data z banky.</div>
                <Form onSubmit={importData} initialValues={{bankId: item.bankId}} render={({handleSubmit, values}) => {
                    return (
                        <div>
                            <div className={"flex flex-wrap w-full"}>
                                <div className={"flex flex-row"}>
                                    <Field name={"file"} render={() => (
                                        <input type={"file"} onChange={(e) => setFile(e.target.files[0])}/>
                                    )}/>
                                    <Button text={"Importovat data z banky"} onClick={handleSubmit}
                                            loading={loadingButton}/>
                                </div>
                            </div>
                        </div>)
                }}/>

            </>

        )
    }
    const _transactionWithout = (item) => {
        if (item.data?.itemsWithout.length === 0) return null;
        let columns = [
            {Header: 'Prefix banky', accessor: "key"},
            {
                Header: 'Celkem přijato',
                accessor: d => (<Currency type={"text"} currency={item.currency} price={d.onlineSales}/>)
            },
            {
                Header: 'Vráceno z prodejů předchozích m.',
                accessor: d => (<Currency type={"text"} currency={item.currency} price={d.onlineReturn}/>)
            },
            {
                Header: 'Vráceno z prodejů tento m.',
                accessor: d => (<Currency type={"text"} currency={item.currency} price={d.onlineReturnInMonth}/>)
            }
        ]

        return <>
            <div className={"text-base text-red-400 font-bold"}>Pozor existuje prefix, který není obsažen u žádného
                subjektu.
            </div>
            <Datatable data={item.data?.itemsWithout} columns={columns}/>
        </>
    }

    const renderBank = () => {
        return data.map(item => {
            return (
                <div className={"flex flex-col w-full"}>
                    <h4 className={"font-bold"}>{item.bankName}</h4>
                    <div className={"flex flex-row"}>
                        <div className={"w-1/2"}>
                            <div className={"text-base"}>Online prodeje: <Currency type={"text"}
                                                                                   currency={item.currency}
                                                                                   price={item.data.onlineSales}/></div>
                            <div className={"text-base"}>Online vratky: <Currency type={"text"} currency={item.currency}
                                                                                  price={item.data.onlineReturn}/></div>
                            <div className={"text-base"}>Celkem:
                                <Currency type={"text"} currency={item.currency}
                                          price={item.data.onlineSales - item.data.onlineReturn - item.data.onlineReturnInMonth}/>
                            </div>
                            <div className={"text-base"}>Poplatky: <Currency currency={item.currency} type={"text"}
                                                                             price={item.data.onlineFee}/></div>
                        </div>
                        <div className={"w-1/2"}>
                            <div className={"text-base"}>Počet úspěšných
                                transakcí: {item.data.onlineSalesTransaction}</div>
                            <div className={"text-base"}>Počet vrácených
                                transakcí: {item.data.onlineReturnTransaction}</div>

                        </div>
                    </div>

                    {_transactionWithout(item)}
                    {props?.user?.rights?.billing?.csobImport && _importComponent(item)}
                </div>
            )
        })
    }

    if (loading) return <Loader/>
    if (!data) return null
    return (
        <div className={"flex flex-col"}>
            <div className={"flex flex-row pt-4 pb-4"}>
                <Button text={"Stáhnout všechny platby"} onClick={() => get("XLS_LIST")} loading={loadingButton}/>
            </div>
            {renderBank()}
        </div>
    )
}

export default PaymentGateway
