import React, {useState} from "react";
import Widget from "../../components/widget";

import {apiCreateUser} from "./Actions";
import UserForm from "./UserForm";
import Header from "../../components/My/Header";
import {useNavigate} from "react-router-dom";
import Toast from "cogo-toast";

const AddUser = () => {

    let [loading, setLoading] = useState(false)
    let navigate = useNavigate()
    const save = (values) => {
        setLoading(true)
        apiCreateUser(values, (data) => {
            setLoading(false)
            navigate("/system/users");
            Toast.success("Dokončeno.")
        }, error => {
            switch (error?.type){
                case "USER_EXIST":
                    Toast.warn("Uživatelské jméno již existuje.")
                    break;
                default:
                    Toast.warn("Došlo k neočekávané chybě.")
            }
            setLoading(false)
        })
    }

    return (
        <Header data={[
            {text: "Uživatelé", link: "/system/users"},
            {text: "Přidat uživatele", isMain: true}
        ]}>
            <Widget>
                <UserForm acceptFunction={save} init={null} loading={loading} />
            </Widget>
        </Header>
    )
}

export default AddUser
