import React, {useEffect, useState} from "react";
import Widget from "../../../../../components/widget";
import Header from "../../../../../components/My/Header";
import {apiGetBillingDetailBySubject, apiGetRecalculateSubjectBilling} from "../../../Actions";
import {useParams} from "react-router-dom";
import Loader from "../../../../../components/My/Loader";
import Toast from "cogo-toast";
import {Button} from "../../../../../components/My/Button";
import Modal from "../../../../../components/My/Modal";
import FileDownload from "js-file-download";
import {IconTabs} from "../../../../../components/tabs";
import FormBilling from "./FormBilling";
import Advance from "./Advance";
import MobileApplication from "./MobileApplication";


const SetBilling = () => {

    let [data, setData] = useState(null)
    let [loading, setLoading] = useState(false)
    let [modalCountAgain, setModalCountAgain] = useState(false)
    let [buttonCountAgain, setButtonCountAgain] = useState(false)
    let [buttonFile, setButtonFile] = useState(false)

    const {billingId} = useParams()
    const {subjectId} = useParams()


    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = (type = null) => {
        setLoading(true)
        apiGetBillingDetailBySubject(billingId, subjectId, type, (data) => {
            setData(data)
            setLoading(false)
        }, error => {
            Toast.warn("Došlo k chybě")
            setLoading(false)
        })
    }
    const calculate = () => {
        setButtonCountAgain(true)
        apiGetRecalculateSubjectBilling(billingId, subjectId, (data) => {
            getDetail()
            setModalCountAgain(false)
            setButtonCountAgain(false)
        }, error => {
            Toast.warn("Došlo k chybě")
            setButtonCountAgain(false)
        })
    }
    const getFile = () => {
        setButtonFile(true)
        apiGetBillingDetailBySubject(billingId, subjectId, "xls", (data) => {
            let blob = new Blob([data.data], {type: "application/vnd.ms-excel"});
            FileDownload(blob, data.headers['content-disposition'].replace('attachment; filename=', ''))
            setButtonFile(false)
        }, error => {
            Toast.warn("Došlo k chybě")
            setButtonFile(false)
        })
    }

    const getTabs = () =>{
        let index = 1;
        let tab = [
            {
                index: 0,
                title: <div>Data z vyúčtování</div>,
                content: <>
                    <FormBilling data={data} getDetail={getDetail} />
                </>
            }
        ]
        if(data?.subject?.isMobileApp){
            tab.push({
                index: index,
                title: <div>Mobilní aplikace</div>,
                content: <MobileApplication {...data} />
            })
            index++
        }

        tab.push({
            index: index,
                title: <div>Zálohy ve vyúčtování</div>,
            content: <Advance {...data} get={getDetail} billingId={billingId} subjectId={subjectId}/>
        })

        index++
        tab.push({
                index: index,
                title: <div>Soubory</div>,
                content: <div></div>
        })

        return tab
    }

    const getBody = () => {
        if (loading) return <Loader/>
        if (!data) return null
        if (data) {
            return (
                <>
                    <Widget>
                        <div className={"flex flex-row"}>

                            {getStatus()}

                            <span className="ml-auto"></span>
                            <div className={"flex mr-1"}>
                                <Button text={"Otevřít administraci"} link={data.subject.urlAddress + "/admin"}
                                        target={"_blank"}/>
                            </div>
                            <div className={"flex mr-1"}>
                                <Button text={"Stáhni xls"} loading={buttonFile} onClick={getFile}/>
                            </div>
                            <div className={"flex"}>
                                <Button text={"Spočítat znovu"} hasRight={data.billing.open}
                                        onClick={() => setModalCountAgain(true)}/></div>
                        </div>

                        <div className={"flex flex-col mt-2 text-base"}>
                            {data.billing.rules.postageMakeSubject && <div className={"font-bold text-red-400"}># Subjekt si poštovné odbavuje sám.</div>}
                            {data.billing.rules.specialBilling && <div className={"font-bold text-red-400"}># Subjekt označen, že nejde vyúčtovat automaticky!</div>}
                            {data.billing.rules.specialBillingNote && <div className={"font-bold text-red-400"}># Pozn: {data.billing.rules.specialBillingNote}</div>}
                            {data.billing.rules.commisionFromSalesWithoutDph && <div className={"font-bold text-red-400"}># Provize je počítaná z částky prodejů bez DPH(12%).</div>}
                            {data.billing.rules.commisionFromSalesWithoutVoucher && <div className={"font-bold text-red-400"}># Provize je počítaná z částky prodejů, bez prodejů vstupenek uhrazených voucherem.</div>}
                            {data?.note && <div className={"font-bold text-red-400"}># {data?.note}</div>}
                        </div>
                    </Widget>
                    <IconTabs tabs={getTabs()}/>
                </>
            )
        }
    }


    const getStatus = () => {
        let result = null
        if (data.status === 0) result = <div className={"text-orange-600 font-bold"}> Čeká na zpracování</div>
        if (data.status === 1) result = <div className={"text-red-600 font-bold"}> Nelze dokončit</div>
        if (data.status === 2) result = <div className={"text-green-600 font-bold"}> Dokončeno</div>
        if (data.status === 3) result = <div className={"text-green-600 font-bold"}> Dokončeno - import do abry manuálně</div>
        if (data.status === 4) result = <div className={"text-blue-600 font-bold"}> Neaktivní měsíc</div>
        if (data.status === 5) result = <div className={"text-blue-600 font-bold"}> Vyúčtování po akci manuálně</div>


        if (data?.billing?.future) result = <div className={"text-orange-600 font-bold"}> Aktuální měsíc - nelze vyúčtovat</div>

        return <div className={"flex text-base "}>Stav vyúčtování: {result}</div>
    }
    const getName = () => (data?.billing?.year ? "(" + data?.billing?.month + "/" + data?.billing?.year + ")" : "")
    const getNameSubject = () => (data?.subject?.name ? data?.subject?.name : "")

    return (
        <Header data={[
            {text: "Vyúčtování", link: "/billing/list"},
            {text: "Detail vyúčtování" + getName(), link: "/billing/detail/" + billingId},
            {text: getNameSubject(), isMain: true},
        ]}>

            {getBody()}

            <Modal body={"Opravdu chcete znovu spočítat vyúčtování? "} title={"Vyúčtování"} visible={modalCountAgain}
                   close={() => setModalCountAgain(false)}
                   footer={<>
                       <Button outline={true} text={"Potvrdit"} loading={buttonCountAgain} onClick={() => calculate()}/>
                       <Button outline={true} text={"Zavřít"} onClick={() => setModalCountAgain(false)}/>
                   </>
                   }
            />
        </Header>

    )
}


export default SetBilling
