import React from "react"
import {Form} from "react-final-form";
import {SET_FILTER} from "../../reducers/filters";
import {useDispatch} from "react-redux";
import CheckboxField from "../../FinalForm/CheckboxField";

export const Filter = ({get,filter}) => {

    let dispatch = useDispatch()

    const getData = (values) => {
        dispatch({type: SET_FILTER, page: "billingListFilter", payload: values})
        get(values)
    }

    return(
            <Form onSubmit={getData} initialValues={filter} render={({handleSubmit}) => (
                <>
                    <div className={"flex flex-wrap"}>
                        <div className={"lg:w-1/4 md:w-1/2 w-full p-2"}>
                            <CheckboxField name={"hidden"} label={"Zobrazit skryté"} isMandatory={false} change={handleSubmit} />
                        </div>
                    </div>
                </>
            )}/>
    )
}
