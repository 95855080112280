import {instance, onErrorFunction} from "../../config/axios-cnf";


export const apiGetStatsDaily = (filter, callback, error) => {
    if (filter.typeExport === 'XLS_LIST') {
        instance.get("/api/v2/stats/daily", {params: filter, responseType: 'arraybuffer'})
            .then(response => callback(response))
            .catch(err => error(onErrorFunction(err)))
    } else {
        instance.get("/api/v2/stats/daily", {params: filter})
            .then(response => callback(response.data))
            .catch(err => error(onErrorFunction(err)))
    }

}
