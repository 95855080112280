import React from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Bar} from 'react-chartjs-2'
import {getColor, toRGB, isDarkPalette} from '../../../functions/colors'


const TotalSalesGraph = ({
  height = 300,
  color1 = 'bg-pink-500',
  color2 = 'bg-blue-500',
  color3 = 'bg-teal-500',
  color4 = 'bg-orange-500',
  data= null, typeOfData = "price"
}) => {
  const {palettes, collapsed, layout} = useSelector(
    state => ({
      palettes: state.palettes,
      collapsed: state.collapsed,
      layout: state.layout
    }),
    shallowEqual
  )
  const {background} = {...palettes}
  const isDark = isDarkPalette(background)
  const key = `${layout}-${collapsed}-${background}`

  const getData = (typeOfSale) =>{
   let array = []
   let month = [0,1,2,3,4,5,6,7,8,9,10,11]
    month.map(i =>{
      let total = 0;
      data['byDates'].map(year =>{
        if(year.month[i][typeOfSale]) {
          Object.keys(year.month[i][typeOfSale]).map(type => {
            total += year.month[i][typeOfSale][type][typeOfData]
          })
        }
        array.push(total)
      })

    })
    return array
  }

  const labels = [
    ...[
      'Leden',
      'Únor',
      'Březen',
      'Duben',
      'Květen',
      'Červen',
      'Červenec',
      'Srpen',
      'Září',
      'Říjen',
      'Listopad',
      'Prosinec'
    ].map(i => `${i} 2024`)
  ]

  const legend = {
    display: true,
    position: 'bottom',
    labels: {
      fontColor: isDark ? getColor('text-grey-100') : getColor('text-grey-900'),
      boxWidth: 10,
      fontSize: 11
    }
  }

  const options = {
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          // Formátování hodnoty s mezerou mezi tisícema
          label += tooltipItem.yLabel.toLocaleString(); // Použijeme metodu toLocaleString pro formátování čísla
          return label;
        },
        footer: function(tooltipItems, data) {
          let sum = 0;
          tooltipItems.forEach(function(tooltipItem) {
            sum += parseFloat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
          });
          return 'Celkem: ' + sum.toLocaleString();
        }
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    animation: {
      duration: 0
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10
      }
    },
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          ticks: {
            fontColor: isDark
              ? getColor('text-grey-100')
              : getColor('text-grey-900'),
            min: 0,
            maxTicksLimit: 10,
          },
          gridLines: {
            drawBorder: true,
            display: true,
            color: 'rgba(0, 0, 0, 0)'
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontColor: isDark
              ? getColor('text-grey-100')
              : getColor('text-grey-900'),
            min: 0,
            maxTicksLimit: 10,
            callback: function(value, index, values) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
          },
          gridLines: {
            drawBorder: true,
            display: true,
            color: 'rgba(0, 0, 0, 0)'
          }
        }
      ]
    }
  }

  const generateData = {
    labels: labels,
    datasets: [
      {
        label: 'Pokladna',
        backgroundColor: toRGB(getColor(color1), 1),
        borderColor: toRGB(getColor(color1), 1),
        data: getData("cashier", typeOfData)
      },
      {
        label: 'Online',
        backgroundColor: toRGB(getColor(color2), 1),
        borderColor: toRGB(getColor(color2), 1),
        data: getData("online", typeOfData)
      },
      {
        label: 'Mobilní aplikace',
        backgroundColor: toRGB(getColor(color3), 1),
        borderColor: toRGB(getColor(color3), 1),
        data: getData("mobile", typeOfData)
      },
      {
        label: 'Fakturace',
        backgroundColor: toRGB(getColor(color4), 1),
        borderColor: toRGB(getColor(color4), 1),
        data: getData("invoice", typeOfData)
      }
    ]
  }

  return (
    <div style={{height: height}}>
      <Bar
        key={key}
        data={generateData}
        height={height}
        options={options}
        legend={legend}
      />
    </div>
  )
}

export default TotalSalesGraph
