import React from "react"
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import {Button} from "../../components/My/Button";
import moment from "moment";
import Loader from "../../components/My/Loader";

const TableData = ({data, loading}) => {

    if(loading) return <Loader/>
    if (!data) return null

    let columns = [
        {Header: 'Login', accessor: "email"},
        {Header: 'Jméno', accessor: "name"},

        {
            Header: 'Poslední akce', accessor: "lastAction",
            Cell: ({cell: {value}}) => <div>{value && moment(value).format("DD.MM.YYYY HH:mm:ss")}</div>
        },
        {
            Header: 'Možnosti', accessor: d => (
                <Button text={"Upravit"} link={"/system/users/edit/" + d.id}/>
            )
        }
    ];


    return (
        <Widget>
            <Datatable data={data} columns={columns}/>
        </Widget>
    )
}

export default TableData
