import React, {useState} from "react";
import {Form} from "react-final-form";
import {Button} from "../../../components/My/Button";
import {apiUpdateSubjectMarketData} from "../Actions";
import Toast from "cogo-toast";
import InputField from "../../../FinalForm/InputField";



const Market = ({data, renew}) => {

    let [loading, setLoading] = useState(false)

    const acceptFunction = (values) => {
        setLoading(true)
        apiUpdateSubjectMarketData(data.id, values, (data) => {
            renew()
            setLoading(false)
            Toast.success("Dokončeno.")
        }, err => {
            setLoading(false)
            Toast.warn("Při ukládání došlo k chybě.")
        })
    }
    const init = () => {
        return data.marketData
    }


    return (
        <Form onSubmit={acceptFunction} initialValues={init()} render={({handleSubmit, values}) => {
            return (
                <div>
                    <div className={"flex flex-wrap w-full"}>
                        <div className={"w-full flex flex-col bg-grey-200 mt-1 p-2 rounded"}>
                            <div className={"flex w-full"}>
                                <InputField label={"Zobrazený název subjektu na marketu"} name={"nameOnWeb"} inputType={"text"}
                                            isMandatory={true} />
                            </div>

                            <div className={"flex w-full"}>
                                <InputField label={"Adresa prodejního kanálu"} name={"urlCanal"} inputType={"text"}
                                            isMandatory={true} />
                            </div>
                            <div className={"flex w-full"}>
                                <InputField label={"Adresa obrázku"} name={"urlImage"} inputType={"text"}
                                            isMandatory={true} />
                            </div>
                            <div className={"flex w-1/4"}>

                            </div>
                        </div>
                    </div>
                    <Button text={"Uložit"} onClick={() => handleSubmit()} loading={loading}/>
                </div>
            )
        }}/>
    )

}

export default Market
