import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import {NumericFormat} from "react-number-format";

const CurrencyField = (props) => {

    const getCurrency = ()=>{
        if(props.currency === "EUR") return " EUR"
        if(props.currency === "CZK") return " Kč"

        return " Kč"
    }

    const errorMessage = (message) => {
        return <span style={{color: "red", marginRight: "10px"}}><i className="fa fa-arrow-up"> </i> {message}</span>;
    }

    const renderError = (_props) => {
        if (!props.viewError) return null
        if (_props.meta.error && _props.meta.touched) {
            if (props.isMandatory && !_props.input.value && _props.input.value !== 0) return errorMessage("Povinné pole")
        }
        return null;
    }

    const _renderClassName = (meta, value) => {
        if(props.disabled) return 'form-input ';
        let className = `form-input w-full text-sm `;
        if (meta.touched && meta.error) {
            className += " border-red-500 ";
        } else if (value) {
            className += " border-green-500 ";
        }

        return className;
    };

    return (
        <Field name={props.name}
                   id={props.name + props.value}
                       validate={(value) => {
                           if (props.isMandatory) {
                               if (!value || value < props.minValue) return true;
                           }
                       }}
                   render={(_props) => {
                       return (
                           <div className={"p-1"}>
                               {props.label && (
                                   <div className="form-label font-bold flex flex-col">
                                    <span>{props.label+": "}{props.isMandatory ? <sup>*</sup> : ""}</span>
                                            {props.note && <span className={"text-sm font-normal"}>{props.note}</span>}
                                   </div>

                               )}
                           <NumericFormat thousandSeparator={" "}
                                          decimalSeparator={","}
                                          fixedDecimalScale={true}
                                          decimalScale={2} suffix={getCurrency()}
                                          value={_props.input.value}
                                          displayType={props.type}
                                          className={_renderClassName(_props.meta, _props.input.value)}
                                          disabled={props.disabled}
                                          placeholder={props.placeholder}
                                          autoComplete={"off"}
                                          onValueChange={(value) => {
                                              _props.input.onChange(value.value)
                                              if (props.onChangeField) {
                                                  props.onChangeField(value.value)
                                              }
                                          }}/>
                                 {renderError(_props)}
                           </div>
                       )
                   }}
            />
    )
}

CurrencyField.defaultProps = {
    currency: "CZK",
    minValue: 0
}

CurrencyField.propTypes = {
    name: PropTypes.string,
    currency: PropTypes.string,
    minValue: PropTypes.number
}

export default CurrencyField
