import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";


const PercentField = (props) => {


    /*const errorMessage = (message) => {
        return <span style={{color: "red", marginRight: "10px"}}><i className="fa fa-arrow-up"> </i> {message}</span>;
    }

    const renderError = (_props) => {
        if (!props.viewError) return null
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return errorMessage("Povinné pole")
        }
        return null;
    }*/


    return (
        <Field name={props.name}
                   id={props.name + props.value}
                   render={(_props) => {
                       return (
                           <input{...props}
                               type={props.inputType}
                               className={"w-full h-8 pl-1"}
                               disabled={props.disabled}
                               value={_props.input.value}
                               onChange={(e) => {
                                   _props.input.onChange(e.target.value);
                                   if (props.onChangeField) {
                                       props.onChangeField(e.target.value);
                                   }
                               }}
                               id={props.id}
                               onKeyDown={({ key }) => {
                                   if (key === "Enter") {
                                       if (props.onEnter) props.onEnter();
                                   }
                               }}
                           />
                       )
                   }}
            />
    )
}

PercentField.defaultProps = {}

PercentField.propTypes = {
    name: PropTypes.string,
    selfClass: PropTypes.string
}

export default PercentField
