import React from "react"
import {Form} from "react-final-form";
import {SET_FILTER} from "../../../reducers/filters";
import {useDispatch} from "react-redux";
import UsersField from "../../../FinalForm/api/UsersField";
import SubjectTypeField from "../../../FinalForm/api/SubjectTypeField";
import SelectField from "../../../FinalForm/SelectField";
import SubjectField from "../../../FinalForm/api/SubjectField";
import CountryField from "../../../FinalForm/api/CountryField";
import {Button} from "../../../components/My/Button";

export const Filter = ({get, filter, loadingXls}) => {

    let dispatch = useDispatch()

    const getData = (values) => {
        dispatch({type: SET_FILTER, page: "statsOfSubjectFilter", payload: values})
        get(values)
    }

    return(
            <Form onSubmit={getData} initialValues={filter} render={({handleSubmit, values}) => (
                <>
                    <div className={"flex flex-wrap"}>
                        <div className={"flex lg:w-1/4 md:w-1/2 sm:w-full w-full"}>
                            <SubjectField name={"subjectId"} label={""} isMulti={true} change={handleSubmit}
                                        placeholder={"Vyberte subjekt"} />
                        </div>
                        <div className={"flex lg:w-1/4 md:w-1/2 sm:w-full w-full"}>
                            <UsersField name={"keyAccountId"} label={""} isMulti={true} change={handleSubmit}
                                        placeholder={"Vyberte account m."} filters={{onlyKeyAccount:true}} />
                        </div>
                        <div className={"flex lg:w-1/4 md:w-1/2 sm:w-full w-full"}>
                            <UsersField name={"dealerId"} label={""} isMulti={true} change={handleSubmit}
                                        placeholder={"Vyberte obchodníka"} filters={{onlyDealer:true}} />
                        </div>
                        <div className={"flex lg:w-1/4 md:w-1/2 sm:w-full w-full"}>
                            <SubjectTypeField name={"typeSubjectId"} isMulti={true} placeholder={"Typ subjektu"} change={handleSubmit}/>
                        </div>
                        <div className={"flex lg:w-1/4 md:w-1/2 sm:w-full w-full"}>
                            <SelectField name={"year"} placeholder={"Vyberte rok"} options={[
                                {label: "2024", value: 2024},
                                {label: "2023", value: 2023}
                            ]} change={handleSubmit} />
                        </div>
                        <div className={"flex lg:w-1/4 md:w-1/2 sm:w-full w-full"}>
                            <CountryField name={"countryId"} label={""} isMulti={true} change={handleSubmit}
                                          placeholder={"Vyberte zemi"} />
                        </div>
                        <div className={"flex w-full"}>
                            <Button text={"Stáhnout XLS"} loading={loadingXls} onClick={()=>get(values, 'XLS_LIST')} />
                        </div>
                    </div>
                </>
            )}/>
    )
}
