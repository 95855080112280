import React, {useState} from "react";
import {Form} from "react-final-form";
import {Button} from "../../../components/My/Button";
import {apiUpdateSubjectCommision} from "../Actions";
import Toast from "cogo-toast";
import CommisionMobileApp from "./CommisionMobileApp";
import TextOnInvoice from "./TextOnInvoice";
import OtherCommision from "./OtherCommision";
import ExtendedList from "./ExtendedList";
import CommisionCashier from "./CommisionCashier";
import CommisionOnline from "./CommisionOnline";
import CommisionInvoice from "./CommisionInvoice";


const Commision = ({data, renew}) => {

    let [loading, setLoading] = useState(false)

    const acceptFunction = (values) => {
        setLoading(true)
        apiUpdateSubjectCommision(data.id, values, (data) => {
            renew()
            setLoading(false)
            Toast.success("Dokončeno.")
        }, err => {
            setLoading(false)
            Toast.warn("Při ukládání došlo k chybě.")
        })
    }

    const init = () => {
        if (!data?.commisionData) {
            return {
                online: {
                    ticketPercent: 0.00,
                    ticketPerKs: 0,
                    seasonTicketPercent: 0,
                    seasonTicketPerKs: 0,
                    productPercent: 0,
                    productPerKs: 0
                },
                mobile: {
                    ticketPercent: 0.00,
                    ticketPerKs: 0,
                    seasonTicketPercent: 0,
                    seasonTicketPerKs: 0,
                    productPercent: 0,
                    productPerKs: 0
                },
                cashier: {
                    ticketPercent: 0,
                    ticketPerKs: 0,
                    seasonTicketPercent: 0,
                    seasonTicketPerKs: 0,
                    productPercent: 0,
                    productPerKs: 0,
                    creaOnlinePickCashPercent: 0,
                    creaAdmPickCashPercent: 0,
                    creaCashPickCashPercent: 0
                },
                invoice: {
                    ticketPercent: 0,
                    ticketPerKs: 0,
                    seasonTicketPercent: 0,
                    seasonTicketPerKs: 0,
                    productPercent: 0,
                    productPerKs: 0
                },
                other: {
                    sazka: 0,
                    bankFee: 0,
                    invoiceFee: 0,
                    monthFix: 0,
                    yearFix: 0,
                    minimalPrice: 0,
                    manipulationFee: 0
                }
            }
        }
        return data.commisionData
    }


    return (
        <Form onSubmit={acceptFunction} initialValues={init()} render={({handleSubmit, values}) => {
            return (
                <div>
                    <div className={"flex flex-wrap w-full"}>
                        <CommisionOnline {...values} />
                        <CommisionInvoice {...values} />
                        <CommisionMobileApp {...values}  />
                        <CommisionCashier {...values} />
                        <OtherCommision {...values} />
                        <ExtendedList {...values} />
                        <TextOnInvoice {...values} />
                    </div>
                    <Button text={"Uložit"} onClick={() => handleSubmit()} loading={loading}/>
                </div>
            )
        }}/>
    )

}

export default Commision
