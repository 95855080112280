import React, {useState, useEffect} from "react";
import {instance} from "../../config/axios-cnf";
import SelectField from "../../FinalForm/SelectField";
import PropTypes from "prop-types";
import Toast from "cogo-toast";

const UsersField = (props) => {

    let [subject, setSubject] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => apiGetUsers((data) => {
        let array = [];
        data.map(item => {
            array.push({
                label: item.name,
                value: item.id
            });
        })
        setSubject(array);
        setLoading(false)

    }, props.filters,err => {
        Toast.warn("Došlo k chybě.")
    }), [])

   /* const getFormat = (value) => {
        return value
    }

    const getParse = (value) => {
        if (value === null) return null;
        return value;
    }*/

    return (
        <SelectField {...props} disabled={loading} options={subject} isMandatory={!!props.validate} isMulti={props.isMulti} />
    )


}

UsersField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    isMandatory: PropTypes.bool,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    validate: PropTypes.func,
    change: PropTypes.func,
    value: PropTypes.object,
    filters: PropTypes.object
};
UsersField.defaultProps = {
    isMandatory: false,
    isMulti: false,
    filters: null
};

export const apiGetUsers = (callback, filters, error) => {
    instance.get('/api/users', {params: filters})
        .then(response => callback(response.data))
}

export default UsersField
