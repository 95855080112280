import React, { useEffect, useState } from "react"
import { apiAuthorizate } from "./Actions";
import { useDispatch } from "react-redux";
import { USER_FETCH_SUCCESS, USER_RESET } from "../../reducers/user";
import { useLocation, useNavigate } from "react-router-dom";
import {SET_MENU} from "../../reducers/navigation";
import Layouts from "../../layouts";
import Routing from "../../Routing";


const Auth = ({user}) => {

    let dispatch = useDispatch()
    let [done, setDone] = useState(false);
    const location = useLocation();
    const navigation = useNavigate();

    const getRoute = (data) => {
        if (location.pathname === "/" || location.pathname === "/login") {
            navigation("/dashboard");
        }
    }

    useEffect(() => {
        authorizate()
    }, [])

    const authorizate = () => {
        setDone(false)
        apiAuthorizate(data => {
            dispatch({ type: USER_FETCH_SUCCESS, payload: data });
            dispatch({ type: SET_MENU, rights: data?.rights });
            setDone(true)
            getRoute(data)
        }, () => {
            localStorage.removeItem('authorization-turn-enigoo')
            dispatch({ type: USER_RESET })
            setDone(true)
        })
    }

    if(!done) return <div>Loader</div>

    return (
        <Layouts>
            <Routing user={user}/>
        </Layouts>
    );
}
export default Auth
