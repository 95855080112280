import axios from "axios";
import Toast from "cogo-toast";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {USER_RESET} from "../reducers/user";
import {config} from "./index";

export const instance = axios.create({
    baseURL: config.backend.apiBaseUrl,
    // baseURL: process.env.NODE_ENV === "development" ? "http://webadmin.local.cz" : "/",
});

instance.interceptors.response.use(undefined, function (error) {
    switch (error?.response?.status) {
        case 401: {
            localStorage.removeItem("authorization-webad-enigoo");
            Toast.success("Došlo k odhlášení. Prosím přihlašte se znovu.");

            let dispatch = useDispatch();
            dispatch({type: USER_RESET});
            let navigate = useNavigate();
            navigate("/login");
            break;
        }
        case 403 : {
            Toast.warn("Pro tuto operaci nemáte dostatečná oprávnění.");
            break;
        }
        default:
            return Promise.reject(error);
    }

});

export const onErrorFunction = (error) => {
    if (error?.response?.data) {
        return error.response.data
    } else {
        return {type: null, message: null, data: null}
    }
}

