import React, {useState} from "react";
import {Form} from "react-final-form";
import InputField from "../../FinalForm/InputField";
import {Button} from "../../components/My/Button";
import Modal from "../../components/My/Modal";
import {apiChangePassword} from "./Actions";
import md5 from "md5"


const ModalPassword = ({close, visible}) => {

    let [loading, setLoading] = useState(false)
    let submit

    const acceptFunction = (values) => {
        setLoading(true)
        let data = {
            password: md5(values.password)
        }
        apiChangePassword(data, (data)=>{
            close(true)
            setLoading(false)
        }, err =>{
            setLoading(false)
        })
    }

    const validate = (values) => {
        let error = {}
        if(values.password !== values.passwordCheck){
            error.passwordCheck = true
        }else{
            const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(.{8,})$/;
            const isPasswordValid = passwordPattern.test(values.password);

            if(!isPasswordValid) error.passwordCheck = true
        }


        return error
    }

    const getBody = () => {

        return (
            <Form onSubmit={acceptFunction} validate={validate} render={({handleSubmit, values}) => {
               submit = handleSubmit
                return (
                    <div>
                        <div className={"w-full"}>
                            <ul>
                                <li>Minimálně 10 znaků.</li>
                                <li>Jedno velké písmeno.</li>
                                <li>Speciální znak</li>
                            </ul>
                        </div>
                        <div className={"flex flex-wrap w-full"}>
                            <div className={"w-full"}>
                                <InputField label={"Heslo"} name={"password"} isMandatory={true}/>
                            </div>
                            <div className={"w-full"}>
                                <InputField label={"Heslo znovu"} name={"passwordCheck"} isMandatory={true}/>
                            </div>
                        </div>
                    </div>
                )
            }}/>
        )
    }


    if (!visible) return null
    return (
        <>
            <Modal body={getBody()} title={"Změnit heslo"} visible={visible}
                   close={() => close()}
                   footer={
                    <>
                       <Button outline={true} text={"Zavřít"} onClick={() => close()}/>
                       <Button text={"Potvrdit"} onClick={() => submit()} loading={loading}/>
                   </>
                   }
            />
        </>
    )
}

export default ModalPassword
