import React from "react";
import {Link} from "react-router-dom";


export const Button = ({text, onClick, loading, textSize, link = null, icon, extraStyle = "", size ="btn-sm",
                        target =  "_self", hasRight = true}) => {


    if(!hasRight) return null

    let button =
        <button className={`btn ${size} rounded btn-icon bg-orange-500 space-x-1 mt-1 text-white btn-rounded ` + extraStyle}
            onClick={onClick} disabled={loading}>
            {icon}
            <span className={textSize ? textSize : "text-sm"}>{loading ? "Pracuji..." : text}</span>
        </button>;


    if (link) return <Link to={link} target={target} onClick={onClick}>{button}</Link>
    else return button;
}
