import React from "react";
import {Form} from "react-final-form";
import InputField from "../../FinalForm/InputField";
import {Button} from "../../components/My/Button";
import Widget from "../../components/widget";
import CountryField from "../../FinalForm/api/CountryField";
import UsersField from "../../FinalForm/api/UsersField";

const Filter = ({get, loading}) => {

    return (
        <Widget>
            <Form onSubmit={get} render={({handleSubmit}) => (
                <>
                    <div className={"flex flex-row"}>
                        <div className={"flex lg:w-1/4 md:w-1/2 w-full"}>
                            <InputField name={"subjectName"} label={"Název subjektu"} onEnter={handleSubmit}
                                        isMandatory={false}/>
                        </div>
                        <div className={"lg:w-1/4 md:w-1/2 w-full"}>
                            <CountryField name={"countryId"} label={"Země"} onEnter={handleSubmit} isMandatory={false}/>
                        </div>
                        <div className={"lg:w-1/4 md:w-1/2 w-full"}>
                            <UsersField name={"keyAccountId"} label={"Key account"} isMulti={false}
                                        placeholder={"Vyberte account m."} filters={{onlyKeyAccount: true}}/>
                        </div>
                        <div className={"lg:w-1/4 md:w-1/2 w-full"}>
                            <UsersField name={"dealerId"} label={"Obchodník"} isMulti={false}
                                        placeholder={"Vyberte obchodníka"} filters={{onlyDealer: true}}/>
                        </div>
                    </div>
                    <div className={"lg:w-1/4 md:w-1/2 w-full"}>
                        <Button text={"Vyhledat"} loading={loading} onClick={handleSubmit}/>
                    </div>
                </>
            )}/>

        </Widget>
    )
}

export default Filter
