import React from 'react'
import {
    FiBarChart,
    FiCalendar,
    FiCompass, FiDatabase, FiDollarSign,
    FiList, FiSettings, FiTag, FiTrello, FiUser,
} from 'react-icons/fi'
import {count} from "react-table/src/aggregations";

export const SET_MENU = "SET_MENU";


export default function navigation(state = [], action) {
    switch (action.type) {
        case SET_MENU: {
            return getMenuItems(action.rights)
        }
        default:
            return state
    }
}

const getMenuItems = (rights) => {
    const initialState = [
        {
            title: 'Možnosti',
            items: [{url: '/dashboard', icon: <FiCompass size={20}/>, title: 'Dashboard', items: []}],
            routes: []
        }
    ]

    if(rights?.stats?.daily){
        let i = []
        i.push({url: '/stats/daily', icon: <FiCalendar size={20}/>, title: 'Denní statistika', items: [], key: "daily-stats"})
        if(rights?.stats?.daily) initialState[0].routes.push("/stats/daily")

        initialState[0].items.push({url: null, icon: <FiBarChart size={20}/>, title: 'Statistiky', items: i, key: "stats"})
    }

    if(rights?.billing?.view){
        initialState[0].items.push({url: '/billing/list', icon: <FiDollarSign size={20}/>, title: 'Vyúčtování', items: [], key: "billing"})
        initialState[0].routes.push("/billing/list")
        if(rights?.billing?.changeSubject) initialState[0].routes.push("/billing/detail/:billingId/set/:subjectId")
    }

    if(rights?.bankInvoices?.order || rights?.bankInvoices?.listPayment) {
        let i = []

        if(rights?.bankInvoices?.listPayment){
            i.push({url: '/bank-invoices/list-payment', icon: <FiList size={20}/>, title: 'List plateb', items: [], key: "bank-invoices"})
            initialState[0].routes.push("/bank-invoices/list-payment");
        }
        if(rights?.bankInvoices?.order){
            i.push({url: '/bank-invoices/list-order', icon: <FiTag size={20}/>, title: 'Objednávky', items: [], key: "bank-invoices"})
            initialState[0].routes.push("/bank-invoices/list-order");
        }

        initialState[0].items.push({url: '/bank-invoices', icon: <FiTrello size={20}/>, title: 'Fakturace banka', items: i, key: "bank-invoices"})
    }
    let systemRights = [];

    if (rights?.system?.users?.view) {
        systemRights.push({url: '/system/users', icon: <FiUser size={20}/>, title: 'Uživatelé', items: [], key: "users"})
        initialState[0].routes.push("/system/users");
    }
    if (rights?.system?.subject?.view){
        systemRights.push({url: '/system/subject', icon: <FiDatabase size={20}/>, title: 'Subjekty', items: [], key: "subject"})
        initialState[0].routes.push("/system/subject");
    }
    if (rights?.system?.config){
        systemRights.push({url: '/system/config', icon: <FiSettings size={20}/>, title: 'Nastavení', items: [], key: "subject"})
        initialState[0].routes.push("/system/config");
    }

    if(count(systemRights) > 0) initialState[0].items.push({url: '', icon: <FiSettings size={20}/>, title: 'Systém', items: systemRights, key: "subject"})


    return initialState
}
