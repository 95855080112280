import React from "react";
import Widget from "../../../components/widget";
import DateTimeField from "../../../FinalForm/DateTimeField";
import {Form} from "react-final-form";
import InputField from "../../../FinalForm/InputField";
import {Button} from "../../../components/My/Button";
import SelectField from "../../../FinalForm/SelectField";
import {SET_FILTER} from "../../../reducers/filters";
import {useDispatch} from "react-redux";


const Filter = ({get, loading, filter}) => {

    let dispatch = useDispatch()

    const getData = (values) => {
        dispatch({type: SET_FILTER, page: "listOfPaymentSummary", payload: values})
        get(values)
    }

    return (
        <Widget>
            <Form onSubmit={getData} initialValues={filter} render={({handleSubmit}) => (
                <>
                    <div className={"flex flex-row"}>
                        <div className={"w-1/4"}>
                            <DateTimeField name={"dateFrom"} label={"Datum od"} isMandatory={false} onEnter={handleSubmit}/>
                        </div>
                        <div className={"w-1/4"}>
                            <DateTimeField name={"dateTo"} label={"Datum od"} isMandatory={false} onEnter={handleSubmit}/>
                        </div>
                        <div className={"w-1/4"}>
                            <InputField name={"price"} label={"Cena"} onEnter={handleSubmit} isMandatory={false}/>
                        </div>
                        <div className={"w-1/4"}>
                            <InputField name={"vs"} label={"Variabilní symbol"} onEnter={handleSubmit} isMandatory={false}/>
                        </div>
                        <div className={"w-1/4"}>
                            <SelectField name={"status"} options={[{label: "Došlo k chybě", value: 1}, {label: "Dokončeno", value: 2}]} label={"Stav transakce"}/>
                        </div>
                    </div>
                    <div className={"w-full text-right"}>
                        <Button text={"Vyhledat"} loading={loading} onClick={handleSubmit}/>
                    </div>
                </>
            )}/>

        </Widget>
    )
}

export default Filter
