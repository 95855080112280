import React, {useState} from "react";
import Modal from "../../components/My/Modal";
import {Button} from "../../components/My/Button";
import Toast from "cogo-toast";
import { apiRecalculateBilling} from "./Actions";


const ModalRecalculateBilling = ({close, visible}) => {

    let [loading, setLoading] = useState(false)

    let item = visible

    const recalculate = () => {
        setLoading(true)
        apiRecalculateBilling(item.id,(data) => {
            close(true)
            setLoading(false)
            Toast.success("Dokončeno.")
        }, error => {
            setLoading(false)
            Toast.warn("Došlo k chybě.")
        })
    }

    const getBody = () => {
        return (

            <div className={"flex flex-wrap w-full"}>
                <h3>Opravdu chcete přepočítat celé vyúčtování ? </h3>
            </div>

        )
    }


    if (!item) return null
    return (
        <>
            <Modal body={getBody()} title={"Přepočítat vyúčtování"} visible={visible} close={() => close(false)}
                   footer={
                       <>
                           <Button outline={true} text={"Zavřít"} onClick={() => close()}/>
                           <Button text={"Potvrdit"} onClick={() => recalculate()} loading={loading}/>
                       </>
                   }
            />
        </>
    )
}

export default ModalRecalculateBilling
