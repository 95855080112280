import React from "react";
import InputField from "../../../FinalForm/InputField";
import CheckboxField from "../../../FinalForm/CheckboxField";

const TextOnInvoice = (props) => {

    return(
        <>
            <span className={"w-full text-base font-bold mt-1"}>Texty na faktuře</span>
            <div className={"w-full flex flex-wrap bg-grey-200 mt-1 p-2 rounded"}>
                <div className={"flex w-1/2 flex-col"}>
                    <div className={"flex"}>
                        <CheckboxField name={"textInvoices.isHeaderNote"} label={"Změna v textu hlavičky"}/>
                    </div>
                    <InputField label={"Text hlavičky"} name={"textInvoices.headerNote"} inputType={"text"}
                                isMandatory={props?.textInvoices?.isHeaderNote} disabled={!props?.textInvoices?.isHeaderNote}/>
                </div>
                <div className={"flex w-1/2 flex-col"}>
                    <div className={"flex"}>
                        <CheckboxField name={"textInvoices.isTextAdvance"} label={"Změna v textu zálohy"}/>
                    </div>
                    <InputField label={"Text zálohy na faktuře"} name={"textInvoices.textAdvance"} inputType={"text"}
                                isMandatory={props?.textInvoices?.isTextAdvance} disabled={!props?.textInvoices?.isHeaderNote}/>
                </div>
                <div className={"flex w-1/2 flex-col"}>
                    <div className={"flex"}>
                        <CheckboxField name={"textInvoices.isOnlyTextInInvoice"} label={"Změna v textu na faktuře"}/>
                    </div>
                    <InputField label={"Text na faktuře"} name={"textInvoices.onlyTextInInvoice"} inputType={"text"}
                                isMandatory={props?.textInvoices?.isOnlyTextInInvoice} disabled={!props?.textInvoices?.isOnlyTextInInvoice}/>
                </div>
            </div>
        </>
    )
}

export default TextOnInvoice
