/*import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";

import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import {persistStore} from "redux-persist";

var createStoreWithMiddleware = null;
if (process.env.NODE_ENV !== "production") {
    createStoreWithMiddleware = applyMiddleware(thunk, createLogger())(createStore);
} else {
    createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
}*/

//const middleware = applyMiddleware(promise(), thunk, createLogger());

import { createLogger } from "redux-logger";
import {applyMiddleware, createStore} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from "redux-thunk";
import rootReducer from './reducers';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let createStoreWithMiddleware = null;
if (process.env.NODE_ENV !== "production") {
    createStoreWithMiddleware = applyMiddleware(thunk, createLogger())(createStore);
} else {
    createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
}

export const store = createStoreWithMiddleware(persistedReducer);
export const persistor = persistStore(store);


export default store;
