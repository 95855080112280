import React, {useEffect, useState} from "react"
import {apiGetBilling} from "./Actions";
import Loader from "../../components/loader";
import Widget from "../../components/widget";
import Datatable from "../../components/datatable";
import Header from "../../components/My/Header";
import {Button} from "../../components/My/Button";
import ModalEditBilling from "./ModalEditBilling";
import ModalCreateBilling from "./ModalCreateBilling";
import ModalRecalculateBilling from "./ModalReacalculateBilling";
import {Filter} from "./Filter";
import {useSelector} from "react-redux";

const Billing = (props) => {

    let [loading, setLoading] = useState(false)
    let [modalEdit, setModalEdit] = useState(null)
    let [modalCreate, setModalCreate] = useState(null)
    let [modalRecalculate, setModalRecalculate] = useState(null)
    let [data, setData] = useState(null)
    let {billingListFilter} = useSelector(state => state.filters)

    useEffect(() => {
        getBilling()
    }, [])

    const getBilling = (filter = billingListFilter) => {
        setData(null)
        setLoading(true)
        apiGetBilling(null, filter, data => {
            setData(data)
            setLoading(false)
        }, error => {
            setLoading(false)
            setData(null)
        })
    }

    const getStatus = (open, future) => {
        if(future) return <div className={"text-orange-600 font-bold"}>Aktuální měsíc - nelze vyúčtovat</div>
        if (open) return <div className={"text-green-600 font-bold"}>Lze editovat</div>
        if (!open) return <div className={"text-red-600 font-bold"}>Uzavřeno</div>
    }

    let columns = [
        {Header: 'Měsíc', accessor: "month"},
        {Header: 'Rok', accessor: "year"},
        {Header: 'Lze editovat', accessor: d => getStatus(d.open, d.future)},
        {
            Header: 'Možnosti', accessor: d => (
                <div className={"flex flex-row"}>
                    <Button text={"Detail"} link={"/billing/detail/" + d.id}/>
                    <Button extraStyle={"ml-1"} text={"Upravit"} onClick={() => setModalEdit(d)}
                        hasRight={props?.user?.rights?.billing?.mainChange}/>
                    <Button extraStyle={"ml-1"} text={"Přepočítat"} onClick={() => setModalRecalculate(d)}
                            hasRight={props?.user?.rights?.billing?.recalculate}/>
                </div>
            )
        }
    ]

    const getBody = () => {
        if (loading) return <Loader/>
        if (!data) return null
        if (data) {
            return (
                <Widget>
                    <Datatable data={data} columns={columns}/>
                </Widget>
            )
        }
    }

    return (
        <Header data={[
            {text: "Vyúčtování", isMain: true}
        ]}>
            <Widget>
                <Filter get={getBilling} filter={billingListFilter} />
            </Widget>
            {getBody()}
            <ModalEditBilling visible={modalEdit} close={(renew) => {
                setModalEdit(null)
                if(renew) getBilling()
            }
            }/>
            <ModalCreateBilling visible={modalCreate} close={(renew) => {
                setModalCreate(null)
                if(renew) getBilling()
            }
            }/>
            <ModalRecalculateBilling visible={modalRecalculate} close={() => {
                setModalRecalculate(null)
            }
            }/>
        </Header>
    )

}

export default Billing
