import React, {useEffect, useState} from 'react'
import Layout from '../../layouts/centered'
import CenteredForm from '../../layouts/centered-form'
import {Form} from "react-final-form";
import InputField from "../../FinalForm/InputField";
import {apiVerifyUser, axiosSetAuthorizationToken} from "./Actions";
import {useDispatch, useSelector} from "react-redux";
import Alert from "../../components/alerts";
import {Button} from "../../components/My/Button";

import {useNavigate} from "react-router-dom";

const LoginVerify = () => {

    let [loading, setLoading] = useState(false)
    let [error, setError] = useState(null)
    let dispatch = useDispatch()
    let user = useSelector(state => state.user)
    const navigate = useNavigate();
    useEffect(() => {
        init()
    }, []);


    const init = () => {
       if(!user.verifyToken){
           navigate("/login")
       }
    }


    const onLogin = (values) => {
        setError(null)
        setLoading(true)
        let data = values
        data.verifyToken = user.verifyToken

        apiVerifyUser(data, (data) => {
            setLoading(false)
            axiosSetAuthorizationToken(data.token, dispatch);
            navigate("/")
        }, error => {
            setLoading(false);
            switch (error.type) {
                case "LOGIN_ERROR":
                    setError("Špatné přihlašovací údaje.");
                    break;
                case "USER_NOT_VERIFED":
                    setError("Účet nebyl dosud verifikován. Klidněte na odkaz v e-mailu.");
                    break;
                default:
                    setError("Došlo k chybě")
            }
        })
    }

    return (
        <Layout>
            <CenteredForm subtitle="Prosím přihlašte se">
                {error && <Alert color={"text-red-500"} outlined={true}>{error}</Alert>}
                <div className={"mt-2"}>
                    <Alert color={"text-gray-500"} outlined={true}>Do e-mailu Vám byl zaslán ověřovací kód.</Alert>
                </div>
                <div className={"mt-3"}>
                    <Form onSubmit={onLogin}
                          render={({handleSubmit}) => (
                              <form className="form flex flex-wrap w-full">
                                  <div className="w-full">
                                      <InputField name={"verifyCode"} label={"Jednorázový kód"} inputType={"text"}
                                                  placeholder={"Kód"} isMandatory={true}
                                                  onEnter={() => handleSubmit()}/>
                                  </div>
                                  <Button onClick={handleSubmit} text={"Přihlásit se"} loading={loading}/>
                              </form>
                          )}
                    />
                </div>
                <div className="flex flex-row w-full mt-3">
                </div>
                <div className="w-full">
          <span>

          </span>
                </div>
            </CenteredForm>
        </Layout>
    )
}

export default LoginVerify
