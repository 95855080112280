import React, {useEffect, useState} from "react"
import Widget from "../../components/widget";
import {Button} from "../../components/My/Button";
import {apiGetSubject} from "./Actions";
import Toast from "cogo-toast";
import Header from "../../components/My/Header";
import Datatable from "../../components/datatable";
import Loader from "../../components/My/Loader";
import Filter from "./Filter";


const Subject = () => {

    let [loading, setLoading] = useState(false)
    let [data, setData] = useState(null)

    useEffect(() => {
        getSubject()
    }, [])

    const getSubject = (values = null) => {
        setLoading(true)
        apiGetSubject(null, values, (data) => {
            setData(data)
            setLoading(false)
        }, (err) => {
            Toast.warn("Došlo k chybě")
            setLoading(false)
        })
    }

    const getSubjectname = (name, urlAddress) => {
        return(
            <div className={"flex flex-col"}>
                <div className={"flex font-bold"}>{name}</div>
                <div className={"flex text-sm"}>{urlAddress}</div>
            </div>
        )
    }

    const getState = (closedSubject) => {
      if(closedSubject) return <div className={"bg-red-100 rounded text-center font-bold"}>Ukončený</div>
      if(!closedSubject) return <div className={"bg-green-100 rounded text-center font-bold"}>Aktivní</div>
    }


    let columns = [
        {Header: 'Název subjektu', accessor: d => getSubjectname(d?.name, d?.urlAddress)},
        {Header: 'Země', accessor: "country.name"},
        {Header: 'Stav', accessor: d => getState(d.closedSubject)},
        {Header: 'Key account', accessor: "keyAccount.name"},
        {Header: 'Obchodník', accessor: "dealer.name"},
        {
            Header: 'Možnosti', accessor: d => (
                <Button text={"Upravit"} link={"/system/subject/edit/" + d.id}/>
            )
        }
    ];

    const getBody = () => {
        if (loading) return <Loader/>
        if (!data) return null
        if (data) {
            return (
                <>
                    <Widget>
                        <Datatable data={data} columns={columns}/>
                    </Widget>
                </>
            )
        }
    }

    return (
        <Header data={[
            {text: "Subjekty", isMain: true}
        ]} rightContent={<div className={"flex"}><Button text={"Přidat subjekt"} link={"/system/subject/add"}/></div>}>
            <Filter loading={loading} get={getSubject}/>
            {getBody()}
        </Header>
    )

}

export default Subject
