import React, {useState} from "react";
import Modal from "../../../components/My/Modal";
import {Button} from "../../../components/My/Button";
import InputField from "../../../FinalForm/InputField";
import {Form} from "react-final-form";
import BankField from "../../../FinalForm/api/BankField";
import {apiSetBankToSubject} from "../Actions";
import Toast from "cogo-toast";


const ModalBank = ({close, visible, data, subjectId}) => {

    let [loading, setLoading] = useState(false)
    let submit

    const acceptFunction = (values) => {
        setLoading(true)
        apiSetBankToSubject(subjectId,values, (data)=>{
            close(true)
            setLoading(false)
            Toast.success("Dokončeno.")
        }, error =>{
            setLoading(false)
            switch (error?.type) {
                case "BANK_EXIST_SUBJECT":
                    Toast.warn("Subjekt již má nastavenou stejnou banku.")
                    break;
                default:
                    Toast.warn("Došlo k neočekávané chybě.")
            }
        })
    }

    const init = () => {
      if(data) return data
        return null
    }

    const getBody = () => {

        return (
            <Form onSubmit={acceptFunction} initialValues={init} render={({handleSubmit, values}) => {
               submit = handleSubmit
                return (
                    <div>
                        <div className={"flex flex-wrap w-full"}>
                            <div className={"w-1/2"}>
                                <InputField label={"Klíč"} name={"key"} isMandatory={true}/>
                            </div>
                            <div className={"w-1/2"}>
                                <BankField label={"Banka"} name={"bankId"} isMandatory={true}/>
                            </div>
                        </div>
                    </div>
                )
            }}/>
        )
    }


    if (!visible) return null
    return (
        <>
            <Modal body={getBody()} title={!data ? "Přidat banku" : "Upravit banku"} visible={visible}
                   close={() => close(null)}
                   footer={<>
                       <Button outline={true} text={"Zavřít"} onClick={() => close()}/>
                       <Button text={"Potvrdit"} onClick={() => submit()} loading={loading}/>
                   </>
                   }
            />
        </>
    )
}

export default ModalBank
