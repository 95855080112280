import React from "react";
import Widget from "../widget";
import {Link} from "react-router-dom";



const Header = ({data = [], rightContent=null, children}) => {

    const _renderContent = () => data.map((item, key) => {

        let text = "";

        if(item?.isMain){
            text = <div className={"text-base font-bold"}>{item.text}</div>
        } else{
            if(item?.link) {
                text = <div className={"flex"}>
                    <Link to={item.link}><div className={"text-base underline"}>{item.text}</div></Link>
                    <div className={"text-base"}>{" >> "}</div>
                </div>
            }else{
                text = <div className={"text-base"}>{item.text} {" >> "}</div>
            }
        }

        return text

    })


    return (
        <>
            <Widget>
                <div className={"flex flex-row text-black align-bottom"}>
                    {_renderContent()}
                    <span className="ml-auto"></span>
                    {rightContent}
                </div>
            </Widget>
            {children}
        </>
    )
}

export default Header
