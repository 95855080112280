import React, {useEffect, useState} from "react";
import Header from "../../../components/My/Header";
import {apiGetListOrders, apiGetListPayment} from "../Actions";
import Loader from "../../../components/My/Loader";
import Widget from "../../../components/widget";
import Filter from "./Filter";
import Table from "./Table";


const ListOrders = () => {


    let [data, setData] = useState(null)
    let [loading, setLoading] = useState(false)

    useEffect(() => {
        get()
    }, [])

    const get = (filters) => {
        setLoading(true)
        apiGetListOrders(filters, (data) => {
            setData(data)
            setLoading(false)
        }, error => {
            setLoading(false)
        })
    }

    const getBody = () => {
        if (loading) return <Loader/>
        if (data) {
            return <Widget><Table data={data}/></Widget>
        }
    }

    return (
        <Header data={[{text: "Fakturace banka - list objednávek", isMain: true}]}>
            <Filter get={get} loading={loading}/>
            {getBody()}
        </Header>
    )
}

export default ListOrders
